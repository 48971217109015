

import RequestLegis from "./RequestLegis"
export default async function RequestLegisArticol(props) {

    if (props.cautaInTitlu != null && props.cautaInTitlu === true) //Cauta in Titlu
    {
        try {
            const result = await RequestLegis.getArticolCautareInTitlu(props.expresie, props.pagina, props.articolePePagina);
            return result;
        } catch (e) {
            console.log('error', e)
        }
    } else if (props.cautaInContinut != null && props.cautaInContinut === true) { //Cauta in Continut
        try {
            const result =  await RequestLegis.getArticolCautareInContinut(props.expresie, props.pagina, props.articolePePagina);
            return result;
        } catch (e) {
            console.log('error', e)
        }
    } 
    else if (props.getArticoleNoi != null && props.getArticoleNoi === true) { //Returneaza Articolele Noi
        try {
            const result =  await RequestLegis.getArticoleNoi(props.pagina, props.articolePePagina);
            return result;
        } catch (e) {
            console.log('error', e)
        }
    }
    else {  //Returneaza Continutul Articolului dupa ArticolId
        try {
            const result = await RequestLegis.getArticolContinutById(props.Id);
            return result;
        } catch (e) {
            console.log('error', e)
        }
    }
}


