import React from 'react';
import './ComandaLegis.css';
import Modal from 'react-bootstrap/Modal';
import RequestLegisAccount from '../../../requests/RequestLegisAccount';
import swal from '@sweetalert/with-react';
class ComandaLegis extends React.Component {
    notificationSystem = React.createRef();
    constructor(props) {
        super(props);

        this.state = {
            showComanda: false,
            pachet:'',
            pachetAnual: false,
            pachetLunar: false
        };

        this.showComanda = this.showComanda.bind(this);
        this.registerComanda = this.registerComanda.bind(this);
        this.onComandaClick = this.onComandaClick.bind(this);
        this.onInputchange = this.onInputchange.bind(this);
    }

    onComandaClick(pachet){
        this.setState({
            pachet:pachet
        })
        this.showComanda(true)
    }

  
    onInputchange(event) {
        if(event.target.name != null && event.target.name == 'pachetAnual'){
                this.setState({
                    pachetAnual : event.target.checked,
                    pachetLunar : !event.target.checked
                })
        }else  if(event.target.name != null && event.target.name == 'pachetLunar'){
            this.setState({
                pachetAnual : !event.target.checked,
                pachetLunar : event.target.checked
            })
        }else{
            this.setState({
                [event.target.name]: event.target.value
            })
        }
    }


    async registerComanda() {

        var errMsg = false;
        
        const errorMessages = [];
        if (this.state.persoanaDeContact ==  null || this.state.persoanaDeContact =='') 
            errMsg = true;
        if (this.state.localitate ==  null || this.state.localitate =='') 
            errMsg = true;
        if (this.state.email ==  null || this.state.email =='') 
            errMsg = true;
        if (this.state.telefon ==  null || this.state.telefon =='') 
            errMsg = true;
        if(errMsg)
        errorMessages.push('Completati toate campurile obligatorii (*)');

            
        if(this.state.email != null && !this.state.email.includes('@'))
        errorMessages.push('Email invalid!');

        if(isNaN(this.state.telefon) && this.state.telefon !=null)
        errorMessages.push('Numar telefon invalid!');

        if(this.state.telefon != null && this.state.telefon.length != 10)
        errorMessages.push('Numar telefon invalid!');


        if(this.state.pachetAnual == false && this.state.pachetLunar == false)
        errorMessages.push('Va rugam selectati modul de facturare dorit!');

        this.setState({ errorMesages: errorMessages });


        if (errorMessages.length === 0) {
            console.log('this.state',this.state)
            //POST to WebApi
            await RequestLegisAccount({
                comanda: true,
                tipPachet : this.state.pachet,
                detaliiPachet : this.state.pachetAnual === true ? 'Pachet Anual' : 'Pachet Lunar',
                numeSocietate: this.state.numeSocietate,
                codFiscal: this.state.codFiscal,
                registrulComertului : this.state.registrulComertului,
                contIBAN : this.state.contIBAN,
                banca : this.state.banca,
                localitate: this.state.localitate,
                judet : this.state.judet,
                adresa : this.state.adresa,
                telefon: this.state.telefon,
                email : this.state.email,
                persoanaDeContact : this.state.persoanaDeContact
            });

            //curata state
            this.setState({
                showComanda: false,
                detaliiPachet : this.state.pachetAnual === true ? 'Pachet Anual' : 'Pachet Lunar',
                numeSocietate: '',
                codFiscal: '',
                registrulComertului : '',
                contIBAN : '',
                banca : '',
                localitate: '',
                judet : '',
                adresa : '',
                telefon: '',
                email : '',
                persoanaDeContact : ''
            })

            swal(
                <div style={{textAlign:'justify'}} >
                  <div>
                  <h3 className="check"></h3>        
                  </div>
                  <div style={{margin:'5%'}}>
                  <strong>Va multumim pentru comanda. </strong>
                  <p>Veti fi contactat de operator pentru procesarea acesteia.</p>
                  </div>
                </div>
              )
        }
    }

    showComanda(val) {
        this.setState({
            showComanda: val
        });
    };

    render() {

        return (
            <div>

                <div className="comandaLegis">

                    <h4>Comanda Legis *</h4>

                    <div class="comparison">

                        <table>
                            <thead>
                                <tr>
                                    <th className="headerTh"> <strong></strong></th>
                                    {/* <th class="qbt">
                                        Start
                                     </th> */}
                                    <th class="qbo">
                                        Essential
                                    </th>
                                    <th class="qbse">
                                        Start
                                    </th>
                                    <th class="qbz">
                                        Practic
                                    </th>
                                    <th class="qbt">
                                        Juridic-Business
                                    </th>
                                    <th class="qbr">
                                        Profesional
                                    </th>
                                </tr>
                                
                                <tr>
                                    {/*
                                    <th class="compare-heading">
                                        Self-Employed
                                    </th>
                                    <th class="compare-heading">
                                        Simple Start
                                    </th>
                                    <th class="compare-heading">
                                        Essentials
                                     </th>
                                    <th class="compare-heading">
                                        Plus
                                    </th>  <th class="compare-heading">
                                        Plus
                                    </th>  <th class="compare-heading">
                                        Plus
                                    </th>*/}
                                </tr>
                                <tr>
                                    <th></th>
                                    {/* <th class="price-info">
                                       <div class="price-was">Was £25.00</div>
                                        <div class="price-now"><strong>550 Ron<span class="price-small"></span> /anual</strong></div>
                                        <div class="price-now"><strong>55 Ron<span class="price-small"></span> /lunar</strong></div>
                                        <div><button type="button" onClick={() => this.onComandaClick('Pachet Start')} class="price-buy">Comanda <span class="hide-mobile" ></span></button></div>
                                    </th> */}
                                    <th class="price-info">
                                       {/* <div class="price-was">Was £25.00</div>*/}
                                        <div class="price-now"><strong>650 Ron<span class="price-small"></span> /anual</strong></div>
                                        <div class="price-now"><strong>65 Ron<span class="price-small"></span> /lunar</strong></div>
                                        <div><button type="button" onClick={() => this.onComandaClick('Essential')} class="price-buy">Comanda <span class="hide-mobile" ></span></button></div>
                                    </th>
                                    <th class="price-info">
                                       {/* <div class="price-was">Was £25.00</div>*/}
                                        <div class="price-now"><strong>750 Ron<span class="price-small"></span> /anual</strong></div>
                                        <div class="price-now"><strong>75 Ron<span class="price-small"></span> /lunar</strong></div>
                                        <div><button type="button" onClick={() => this.onComandaClick('Start')} class="price-buy">Comanda <span class="hide-mobile" ></span></button></div>
                                    </th>
                                    <th class="price-info">
                                       {/* <div class="price-was">Was £25.00</div>*/}
                                        <div class="price-now"><strong>1000 Ron<span class="price-small"></span> /anual</strong></div>
                                        <div class="price-now"><strong>100 Ron<span class="price-small"></span> /lunar</strong></div>
                                        <div><button type="button" onClick={() => this.onComandaClick('Practic')} class="price-buy">Comanda <span class="hide-mobile" ></span></button></div>
                                    </th>
                                    <th class="price-info">
                                       {/* <div class="price-was">Was £25.00</div>*/}
                                        <div class="price-now"><strong>1800 Ron<span class="price-small"></span> /anual</strong></div>
                                        <div class="price-now"><strong>180 Ron<span class="price-small"></span> /lunar</strong></div>
                                        <div><button type="button" onClick={() => this.onComandaClick('Juridic-Business')} class="price-buy">Comanda <span class="hide-mobile" ></span></button></div>
                                    </th>
                                    <th class="price-info">
                                       {/* <div class="price-was">Was £25.00</div>*/}
                                        <div class="price-now"><strong>2500 Ron<span class="price-small"></span> /anual</strong></div>
                                        <div class="price-now"><strong>250 Ron<span class="price-small"></span> /lunar</strong></div>
                                        <div><button type="button" onClick={() => this.onComandaClick('Profesional')} class="price-buy">Comanda <span class="hide-mobile" ></span></button></div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td colspan=""></td>
                                </tr>
                                <tr class="compare-row">
                                    <td>Acte oficiale-Legislatie Ro</td>
                                    {/* <td><span class="tickyellow"><strong>✔</strong></span></td> */}
                                    <td><span class="tickgreen"><strong>✔</strong></span></td>
                                    <td><span class="tickblue"><strong>✔</strong></span></td>
                                    <td><span class="tickpurple"><strong>✔</strong></span></td>
                                    <td><span class="ticklila"><strong>✔</strong></span></td>
                                    <td><span class="tickred"><strong>✔</strong></span></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td colspan="6"></td>
                                </tr>
                                <tr >
                                    <td>Jurisprudenţă</td>
                                    {/* <td></td> */}
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><span class="ticklila"><strong>✔</strong></span></td>
                                    <td><span class="tickred"><strong>✔</strong></span></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td colspan=""></td>
                                </tr>
                                <tr class="compare-row">
                                    <td>Practica Judiciara</td>
                                    {/* <td></td> */}
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><span class="ticklila"><strong>✔</strong></span></td>
                                    <td><span class="tickred"><strong>✔</strong></span></td>
                                </tr>  
                                <tr>
                                    <td></td>
                                    <td colspan=""></td>
                                </tr>
                                <tr>
                                    <td>Jurisprudenta Fiscala</td>
                                    {/* <td></td> */}
                                    <td></td>
                                    <td></td>
                                    <td><span class="tickpurple"><strong>✔</strong></span></td>
                                    <td><span class="ticklila"><strong>✔</strong></span></td>
                                    <td><span class="tickred"><strong>✔</strong></span></td>
                                </tr>  
                                <tr>
                                    <td></td>
                                    <td colspan=""></td>
                                </tr> 
                                <tr class="compare-row">
                                    <td>Jurisprudenta CNSC</td>
                                    {/* <td></td> */}
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><span class="ticklila"><strong>✔</strong></span></td>
                                    <td><span class="tickred"><strong>✔</strong></span></td>
                                </tr>
                                 <tr>
                                    <td></td>
                                    <td colspan=""></td>
                                </tr>
                                <tr >
                                    <td>Doctrine</td>
                                    {/* <td></td> */}
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><span class="ticklila"><strong>✔</strong></span></td>
                                    <td><span class="tickred"><strong>✔</strong></span></td>
                                </tr>   
                                <tr>
                                    <td></td>
                                    <td colspan=""></td>
                                </tr>
                                <tr class="compare-row">
                                    <td>Modele tip</td>
                                    {/* <td></td> */}
                                    <td></td>
                                    <td><span class="tickblue"><strong>✔</strong></span></td>
                                    <td><span class="tickpurple"><strong>✔</strong></span></td>
                                    <td><span class="ticklila"><strong>✔</strong></span></td>
                                    <td><span class="tickred"><strong>✔</strong></span></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td colspan=""></td>
                                </tr>
                                <tr >
                                    <td>Management Achizitii</td>
                                    {/* <td></td> */}
                                    <td></td>
                                    <td></td>
                                    <td><span class="tickpurple"><strong>✔</strong></span></td>
                                    <td><span class="ticklila"><strong>✔</strong></span></td>
                                    <td><span class="tickred"><strong>✔</strong></span></td>
                                </tr>  
                                <tr>
                                    <td></td>
                                    <td colspan=""></td>
                                </tr>
                                <tr class="compare-row">
                                    <td>Clasificari</td>
                                    {/* <td></td> */}
                                    <td></td>
                                    <td></td>
                                    <td><span class="tickpurple"><strong>✔</strong></span></td>
                                    <td><span class="ticklila"><strong>✔</strong></span></td>
                                    <td><span class="tickred"><strong>✔</strong></span></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td colspan=""></td>
                                </tr>
                                <tr >
                                    <td>Dictionare</td>
                                    {/* <td><span class="tickyellow"><strong>✔</strong></span></td> */}
                                    <td><span class="tickgreen"><strong>✔</strong></span></td>
                                    <td><span class="tickblue"><strong>✔</strong></span></td>
                                    <td><span class="tickpurple"><strong>✔</strong></span></td>
                                    <td><span class="ticklila"><strong>✔</strong></span></td>
                                    <td><span class="tickred"><strong>✔</strong></span></td>
                                </tr> 
                                <tr>
                                    <td></td>
                                    <td colspan=""></td>
                                </tr>
                                <tr class="compare-row">
                                    <td>Autorizatii</td>
                                    {/* <td></td> */}
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><span class="tickred"><strong>✔</strong></span></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td colspan=""></td>
                                </tr>
                                <tr >
                                    <td>CEDO</td>
                                    {/* <td></td> */}
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><span class="tickred"><strong>✔</strong></span></td>
                                </tr> 
                                <tr>
                                    <td></td>
                                    <td colspan=""></td>
                                </tr>
                                <tr class="compare-row">
                                    <td>Definitii si abrevieri</td>
                                    {/* <td><span class="tickyellow"><strong>✔</strong></span></td> */}
                                    <td></td>
                                    <td><span class="tickblue"><strong>✔</strong></span></td>
                                    <td><span class="tickpurple"><strong>✔</strong></span></td>
                                    <td><span class="ticklila"><strong>✔</strong></span></td>
                                    <td><span class="tickred"><strong>✔</strong></span></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td colspan=""></td>
                                </tr>
                                <tr >
                                    <td>Jurisprudenta EU</td>
                                    {/* <td></td> */}
                                    <td></td>
                                    <td><span class="tickblue"><strong>✔</strong></span></td>
                                    <td><span class="tickpurple"><strong>✔</strong></span></td>
                                    <td></td>
                                    <td><span class="tickred"><strong>✔</strong></span></td>
                                </tr> 
                                <tr>
                                    <td></td>
                                    <td colspan=""></td>
                                </tr>
                                <tr class="compare-row">
                                    <td>Comentarii Reviste</td>
                                    {/* <td></td> */}
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><span class="tickred"><strong>✔</strong></span></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td colspan=""></td>
                                </tr>
                                <tr >
                                    <td>Coresp. Coduri Juridice</td>
                                    {/* <td></td> */}
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><span class="ticklila"><strong>✔</strong></span></td>
                                    <td><span class="tickred"><strong>✔</strong></span></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td colspan=""></td>
                                </tr>
                                <tr class="compare-row">
                                    <td>Calendar Ev. Fiscale</td>
                                    {/* <td></td> */}
                                    <td></td>
                                    <td></td>
                                    <td><span class="tickpurple"><strong>✔</strong></span></td>
                                    <td></td>
                                    <td><span class="tickred"><strong>✔</strong></span></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td colspan=""></td>
                                </tr>
                                <tr >
                                    <td><strong class="new">Nou</strong><br/> Corespondenta Fiscala</td>
                                    {/* <td></td> */}
                                    <td></td>
                                    <td></td>
                                    <td><span class="tickpurple"><strong>✔</strong></span></td>
                                    <td><span class="ticklila"><strong>✔</strong></span></td>
                                    <td><span class="tickred"><strong>✔</strong></span></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td colspan=""></td>
                                </tr>
                                <tr class="compare-row">
                                    <td><strong class="new">Nou</strong> <br/>  Coresp.Reg. Tribunale</td>
                                    {/* <td></td> */}
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><span class="tickred"><strong>✔</strong></span></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td colspan=""></td>
                                </tr>
                                <tr >
                                    <td>Legislatie EU</td>
                                    {/* <td></td> */}
                                    <td></td>
                                    <td><span class="tickblue"><strong>✔</strong></span></td>
                                    <td><span class="tickpurple"><strong>✔</strong></span></td>
                                    <td><span class="ticklila"><strong>✔</strong></span></td>
                                    <td><span class="tickred"><strong>✔</strong></span></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td colspan=""></td>
                                </tr>
                                <tr  class="compare-row">
                                    <td>Dosare instanta **</td>
                                    {/* <td>-</td> */}
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>30 dosare</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td colspan=""></td>
                                </tr>
                                <tr >
                                    <td>Monitorizare <br/>firme ***</td>
                                    {/* <td>-</td> */}
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>30 firme</td>
                                </tr>
                            </tbody>
                        </table>
                        <div style={{textAlign:'left'}}>
                            *Preturile sunt pentru un user si nu contin TVA. Pentru mai multi useri va rugam sa ne contactati<br/>
                            **Inclusa in pret gestiunea simultana a 30 de dosare distincte<br/>
                            ***Inclusa in pret monitorizarea a 30 de entitati juridice<br/>
                        </div>
                    </div>



                    <div className="col-xs-12 col-md-12 col-lg-12" >


                    <Modal style={{ width: '40%', marginTop: '2%', marginLeft: '30%', marginRight: '30%', overflow: 'auto' }}

                        dialogClassName="modal-dialog-user-nou"
                        show={this.state.showComanda}
                        onHide={() => this.showComanda(false)}
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                Comanda Pachet
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.state.errorMesages != null ? this.state.errorMesages.map((message) => <p className="modals-message" >{message}</p>) : null}
                            <input type="text" className="modals-input form-control" name="numeSocietate" value={this.state.numeSocietate} onChange={this.onInputchange} placeholder="Nume societate sau institutie:" required />
                            <p />
                            <input type="text" className="modals-input form-control" name="codFiscal" value={this.state.codFiscal} onChange={this.onInputchange} placeholder="Cod Fiscal:" required />
                            <p />
                            <input type="text" className="modals-input form-control" name="registrulComertului" value={this.state.registrulComertului} onChange={this.onInputchange} placeholder="Registrul Comertului:"
                                required
                            />

                            <p />
                            <input type="text" className="modals-input form-control" name="contIBAN" value={this.state.contIban} onChange={this.onInputchange} placeholder="Cont IBAN:" required />
                            <p />

                            
                            <input type="text" className="modals-input form-control" name="banca" value={this.state.banca} onChange={this.onInputchange} placeholder="Banca:" required />
                            <p />
                            
                            <input type="text" className="modals-input form-control" name="localitate" value={this.state.contIban} onChange={this.onInputchange} placeholder="Localitate:" required />
                            <p />

                            
                            <input type="text" className="modals-input form-control" name="judet" value={this.state.contIban} onChange={this.onInputchange} placeholder="Judet:" required />
                            <p />

                                 
                            <input type="text" className="modals-input form-control" name="adresa" value={this.state.contIban} onChange={this.onInputchange} placeholder="Adresa:" required />
                            <p />

                            <input type="number" className="modals-input form-control" name="telefon" value={this.state.telefon} onChange={this.onInputchange} placeholder="*Telefon" />
                            <p />

                            <input type="email" className="modals-input form-control" name="email" value={this.state.email} onChange={this.onInputchange} placeholder="*Email:" required />
                            <p />


                            <input type="text" className="modals-input form-control" name="persoanaDeContact" value={this.state.persoanaDeContact} onChange={this.onInputchange} placeholder="*Persoana de Contact - Telefon Mobil:" />
                            <p />
                            Mod de Facturare:<br/><div>
                            <input type="checkbox"  name="pachetLunar" value="pachetLunar" checked={this.state.pachetLunar} onChange={this.onInputchange}/>Lunar&nbsp;
                            <input type="checkbox"  name="pachetAnual" value="pachetAnual" checked={this.state.pachetAnual}  onChange={this.onInputchange}/>Anual 
                            </div>
                            <p />

                         
                            <button type="submit" className="btn btn-secondary" onClick={() => this.registerComanda()}>Trimite Cererea</button>
                            <div className="clearfix" />
                        </Modal.Body>
                    </Modal>
                </div>

                </div>
                <br /><br />
            </div>
        );
    }
}

export default ComandaLegis;