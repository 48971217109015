import React, { Fragment, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import { TableCell } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button } from '@material-ui/core';

const useStylesBody = makeStyles(theme => ({
    arrow: {
        transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    },

    arrowRotated: {
        transform: "rotate(90deg)"
    }
}));

const useStylesFooter = makeStyles(theme => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5)
    }
}));

function TablePaginationActions(props) {
    const classes = useStylesFooter();
    const { count, page, rowsPerPage, onChangePage } = props;

    function handleFirstPageButtonClick(event) {
        onChangePage(event, 0);
    }

    function handleBackButtonClick(event) {
        onChangePage(event, page - 1);
    }

    function handleNextButtonClick(event) {
        onChangePage(event, page + 1);
    }

    function handleLastPageButtonClick(event) {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }

    return (
        <div className={classes.root}>
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0}>
                {<FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0}>
                {<KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            >
                {<KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            >
                {<LastPageIcon />}
            </IconButton>
        </div>
    );
}

function ExtendedTableBody(props) {
    const classes = useStylesBody();
    const {
        columns,
        data,
        dataKey,
        isSelected,
        extendedByDefault,
        handleClick,
        detailPanel,
        emptyRows,
        onStergeClick
    } = props;
    
    return (
        <TableBody>
            {data.map((item, index) => {
                const isItemSelected = isSelected(item, extendedByDefault);

                return (
                    <Fragment key={item[dataKey]}>
                        <TableRow
                            hover
                            onClick={event => handleClick(event, item)}
                            selected={isItemSelected}
                        >
                            {detailPanel && (
                                <TableCell style={{ width: "50px" }}>
                                    <KeyboardArrowRight
                                        fontSize="small"
                                        className={clsx(
                                            classes.arrow,
                                            isItemSelected && classes.arrowRotated
                                        )}
                                    />
                                </TableCell>
                            )}

                            {columns
                                .filter(column => !column.hidden)
                                .map(column => {
                                    const cellContent = column.content(item);

                                    return (
                                        <TableCell align={column.align || "left"}>

                                            {cellContent == 'Optiuni' ?

                                                <div >
                                                    {data.length > 1 ?
                                                        <div>
                                                            <Button variant="contained" color="primary" size="small" onClick={() => props.onStergeClick(item)} > Sterge</Button>
                                                            {index > 0 ?
                                                                <Button variant="contained" color="primary" size="small" style={{ marginLeft: "10px" }} onClick={() => props.onMutaSus(item)} >  &#x25B2;</Button> :
                                                                null}

                                                            {index < data.length - 1 ?
                                                                <Button variant="contained" color="primary" size="small" style={{ marginLeft: "10px" }} onClick={() => props.onMutaJos(item)} > &#x25BC;</Button> :
                                                                null}

                                                        </div>
                                                        :
                                                        <div>
                                                            <Button variant="contained" color="primary" size="small" onClick={() => props.onStergeClick(item)}> Sterge</Button>
                                                        </div>
                                                    }

                                                </div> : cellContent
                                            }
                                        </TableCell>
                                    );
                                })}
                        </TableRow>
                        {detailPanel && isItemSelected && (
                            <TableCell
                                colSpan={columns.length + 1}
                                style={{ transition: "all 500ms" }}
                            >
                                <Fade in={true}>{detailPanel(item)}</Fade>
                            </TableCell>
                        )}
                    </Fragment>
                );
            })}
            {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                    <TableCell colSpan={columns.length + Boolean(detailPanel)} />
                </TableRow>
            )}
        </TableBody>
    );
}

export default function ExtendedTable(props) {
    const rowsPerPage = 5;
    const { columns, data, dataKey, onRowClick, isRemote, detailPanel, extendedByDefault,onStergeClick,onMutaJos,onMutaSus } = props;
    const [fetching, setFetching] = React.useState(false);
    const [page, setPage] = React.useState(props.initialPage || 0);
    var [count, setCount] = React.useState(0);
    const [pageData, setPageData] = React.useState(null);
    const [selectedItem, setSelectedItem] = React.useState(props.selectedItem);
    count = props.count;
    useEffect(() => {
        console.log("RERENDER");
    }, []);

    useEffect(() => {
        async function fetchData(page) {
            setFetching(true);
            data(page).then(responseData => {
                setPageData(responseData["Result"]);
                setCount(responseData["Count"]);
                setFetching(false);
            });
        }

        if (!isRemote) {
            setPageData(
                data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            );
        } else {
            fetchData(page);
        }
    }, [data, page, isRemote]);

    useEffect(() => {
        setSelectedItem(props.selectedItem);
    }, [props.selectedItem]);

    function handleClick(event, item) {
        if (selectedItem && item[dataKey] === selectedItem[dataKey]) {
            setSelectedItem(null);
            if (onRowClick) onRowClick(null);
        } else {
            setSelectedItem(item);
            if (onRowClick) onRowClick(item);
        }
    }

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function isSelected(item, extendedByDefault) {
        if (extendedByDefault != null && extendedByDefault == true)
            return true;
        else
            return selectedItem && item[dataKey] === selectedItem[dataKey];
    }

    return (
        <Paper>
            <Table size="small">
                <TableHead>
                    {detailPanel && <TableCell style={{ width: "50px" }} />}
                    {columns
                        .filter(column => !column.hidden)
                        .map(column => (
                            <TableCell
                                align={column.align || "left"}
                                style={column.width ? { width: column.width } : {}}
                            >
                                {column.name}
                            </TableCell>
                        ))}
                </TableHead>
                {fetching || !pageData ? (
                    <TableCell colSpan={columns.length + Boolean(detailPanel)}>
                        <div
                            style={{
                                height: 33 * 15,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <CircularProgress color="inherit" />
                        </div>
                    </TableCell>
                ) : (
                        <ExtendedTableBody
                            columns={columns}
                            data={pageData}
                            dataKey={dataKey}
                            isSelected={isSelected}
                            handleClick={handleClick}
                            detailPanel={detailPanel}
                            extendedByDefault={props.extendedByDefault}
                            onStergeClick={onStergeClick}
                            onMutaJos = {onMutaJos}
                            onMutaSus = {onMutaSus}
                            emptyRows={rowsPerPage - pageData.length}
                        />
                    )}
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[rowsPerPage]}
                            colSpan={columns.length + Boolean(detailPanel)}
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </Paper>
    );
}
