import React from 'react';
import Menu from './menu/Menu'
import './Body.css';

class Body extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        return( <div className="app-body">
                <Menu {...this.props}/>
        </div>);
    }
}

export default Body;
