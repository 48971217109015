import React from "react";
import RequestLegisAdmin from "../../../requests/RequestLegisAdmin";
import '../administrare/AdministrareAdaugaArtOrdCustom.css';
import ExtendedTable from "../administrare/ExtendedTable.js";
import { Button } from '@material-ui/core';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
class AdministrareVizualizareArtOrdCustom extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pagina: 1,
            articolePePagina: -1,
            expresieSelectata: '',
            expresieSelectataId: 0,
            expresieCustomExpanded: false,
            snackBarVisible: false,
            idArticolNou: 0,
            expresiiCustomLst: [],
            expresiiCustomCopyLst: [],
            articoleExpresieCustomLst: []
        };

        this.onSalveazaModificarile = this.onSalveazaModificarile.bind(this);
        this.onAdaugaArticolNou = this.onAdaugaArticolNou.bind(this);
        this.onStergeClick = this.onStergeClick.bind(this);
        this.onMutaJos = this.onMutaJos.bind(this);
        this.onMutaSus = this.onMutaSus.bind(this);
        this.handleCautaChange = this.handleCautaChange.bind(this);
        this.onCloseSnackBar = this.onCloseSnackBar.bind(this);
        this.handleIdArticolNouChange = this.handleIdArticolNouChange.bind(this);

        if (localStorage.getItem('admin') == null) {
            localStorage.setItem('admin', 'isAdmin');
        }
    }

    async onAdaugaArticolNou() {
        console.log('aici')
        var response = await RequestLegisAdmin({
            articolId: this.state.idArticolNou,
            articolById: true
        });
        if (response != null) {
            if (this.state.articoleExpresieCustomLst != null) {
                var newArt = {
                    IdArticol: response.Id,
                    IdArticolCustom: this.state.articoleExpresieCustomLst.length + 1,
                    Pozitie: this.state.articoleExpresieCustomLst.length + 1,
                    PublicatIn: response.PublicatIn,
                    TitluClean: response.TitluClean,
                    TitluData: response.TitluData,
                    DataIntrariiInVigoare: response.DataIntrariiInVigoareFormatat,
                }

                var articoleExprCustomNewLst = Array.from(this.state.articoleExpresieCustomLst);
                articoleExprCustomNewLst.push(newArt);
                this.setState({
                    articoleExpresieCustomLst: articoleExprCustomNewLst
                })
                console.log('articoleExpresieCustomLst==', this.state.articoleExpresieCustomLst)
                this.forceUpdate()
            }
        }
    }

    async onSalveazaModificarile() {

        try {
            var response = await RequestLegisAdmin({
                expresieId: this.state.expresieSelectataId,
                articoleExpresieCustom: this.state.articoleExpresieCustomLst,
                articoleExpresieCustomChanges: true
            });

            if (response != null) {
                this.setState({
                    snackBarVisible: true
                })
            }
        } catch (e) {

            console.log('error=', e)
        }
    }

    async onExpresieCustomClick(item) {
        try {

            this.setState({
                isLoading: true,
                expresieSelectata: item.Expresie,
                expresieSelectataId: item.Id,
                expresieCustomExpanded: true
            })
            var response = await RequestLegisAdmin({
                idArticolCustom: item.Id,
                articoleExpresieCustom: true
            });

            if (response != null) {
                this.setState({
                    articoleExpresieCustomLst: response
                })
            }

        } catch (e) {
            console.log('error=', e)
        }
    }

    handleCautaChange(event) {
        if (event.target.value != '') {

            var expresiiCustomFiltrateLst = [];
            if (this.state.expresiiCustomCopyLst != null && this.state.expresiiCustomCopyLst.length > 0) {
                for (var i = 0; i < this.state.expresiiCustomCopyLst.length; i++) {
                    var exprCustom = this.state.expresiiCustomCopyLst[i];
                    console.log('exprCustom=', exprCustom)
                    if (exprCustom.Expresie.includes(event.target.value)) {
                        expresiiCustomFiltrateLst.push(exprCustom);
                    }
                }
            }
            this.setState({
                expresiiCustomLst: expresiiCustomFiltrateLst
            })
        } else {
            this.setState({
                expresiiCustomLst: this.state.expresiiCustomCopyLst
            })
        }
    }

    handleIdArticolNouChange(event) {
        console.log('event.target.value', event.target.value)
        this.setState({ idArticolNou: event.target.value });
    }

    onCloseSnackBar() {
        this.setState({
            snackBarVisible: false
        })
    }

    onStergeClick(value) {
        var articoleExpresieCustomFiltrateLst = this.state.articoleExpresieCustomLst.filter(item => value != item)

        if (articoleExpresieCustomFiltrateLst != null && articoleExpresieCustomFiltrateLst.length > 0) {
            this.setState({
                articoleExpresieCustomLst: articoleExpresieCustomFiltrateLst
            })
        }
    }

    onMutaJos(value) {
        var pozitie = value.Pozitie;
        var articoleExpresieCustomFiltrateLst = [];
        if (pozitie >= 0) {
            for (var i = 0; i < this.state.articoleExpresieCustomLst.length; i++) {
                var artExpr = this.state.articoleExpresieCustomLst[i];
                if (i < pozitie - 1) {
                    articoleExpresieCustomFiltrateLst.push(artExpr);
                    continue;
                }
                if (i == pozitie - 1) {
                    var nextArt = this.state.articoleExpresieCustomLst[i + 1]; //(n+1 => devine n)
                    nextArt.Pozitie = nextArt.Pozitie - 1;
                    articoleExpresieCustomFiltrateLst.push(nextArt);
                    continue;
                }
                if (i == pozitie) {
                    value.Pozitie = value.Pozitie + 1;
                    articoleExpresieCustomFiltrateLst.push(value); //(n => devine n+1)
                    continue;
                }
                articoleExpresieCustomFiltrateLst.push(artExpr);
            }
        }

        if (articoleExpresieCustomFiltrateLst != null && articoleExpresieCustomFiltrateLst.length > 0) {
            this.setState({
                articoleExpresieCustomLst: articoleExpresieCustomFiltrateLst
            })
        }
    }

    onMutaSus(value) {
        var pozitie = value.Pozitie;
        var articoleExpresieCustomFiltrateLst = [];
        if (pozitie >= 0) {
            for (var i = 0; i < this.state.articoleExpresieCustomLst.length; i++) {
                var artExpr = this.state.articoleExpresieCustomLst[i];
                if (i < pozitie - 2) {
                    articoleExpresieCustomFiltrateLst.push(artExpr);
                    continue;
                }
                if (i == pozitie - 2) {
                    value.Pozitie = value.Pozitie - 1;
                    articoleExpresieCustomFiltrateLst.push(value); //(n-1 => devine n)
                    continue;
                }
                if (i == pozitie - 1) {
                    var prevArt = this.state.articoleExpresieCustomLst[i - 1]; //(n => devine n-1)
                    prevArt.Pozitie = prevArt.Pozitie + 1;
                    articoleExpresieCustomFiltrateLst.push(prevArt);
                    continue;
                }
                articoleExpresieCustomFiltrateLst.push(artExpr);
            }
        }

        if (articoleExpresieCustomFiltrateLst != null && articoleExpresieCustomFiltrateLst.length > 0) {
            this.setState({
                articoleExpresieCustomLst: articoleExpresieCustomFiltrateLst
            })
        }
        console.log('articoleExpresieCustomLst==', articoleExpresieCustomFiltrateLst)
    }

    async componentDidMount() {
        try {

            this.setState({
                isLoading: true
            })
            var response = await RequestLegisAdmin({
                pagina: this.state.pagina,
                articolePePagina: this.state.articolePePagina,
                expresiiCustom: true
            });

            if (response != null) {
                this.setState({
                    expresiiCustomLst: response,
                    expresiiCustomCopyLst: response
                })
            }
        } catch (e) {
            console.log('error=', e)
        }
    }

    render() {
        let expresiiCustom = this.state.expresiiCustomLst;
        return (
            <div style={{ width: '90%', marginLeft: '5%', marginTop: '2%' }}>
                <div>
                    <h5>Vizualizare Expresii Custom</h5>


                    <AppBar position="static" style={{ background: '#76b887' }}>
                        <Toolbar variant="dense" >
                            <div style={{ margin: '10px' }}>
                                <div>
                                    <SearchIcon />
                                </div>
                                <InputBase
                                    placeholder="Caută…"
                                    onChange={this.handleCautaChange}
                                />
                            </div>
                        </Toolbar>
                    </AppBar>
                    <ExtendedTable
                        columns={[
                            {
                                name: 'Expresie Id',
                                content: e => e.Id,
                                align: 'left',
                                width: '120px',
                            },
                            {
                                name: 'Expresie',
                                content: e => e.Expresie
                            },
                            {
                                name: 'Expresie Solr',
                                content: e => e.ExpresieSolr
                            }
                        ]}
                        data={expresiiCustom}
                        count={expresiiCustom.length}
                        onRowClick={item => this.onExpresieCustomClick(item)}
                        initialPage={0}
                        dataKey="Id"
                    />
                </div>
                <br /><br />
                {this.state.articoleExpresieCustomLst != null && this.state.articoleExpresieCustomLst.length > 0 ?
                    <div>

                        <h5>Articole Expresie: `{this.state.expresieSelectata}`</h5>
                        <ExtendedTable
                            columns={[
                                {
                                    name: 'Pozitie',
                                    content: e => e.Pozitie,
                                    align: 'left',
                                    width: '10%',
                                },
                                {
                                    name: 'Articol Id',
                                    content: e => e.IdArticol,
                                    align: 'left',
                                    width: '10%',
                                },
                                {
                                    name: 'Titlu',
                                    content: e => e.TitluClean,
                                    align: 'left',
                                    width: '50%',
                                },
                                {
                                    name: 'Publicat in',
                                    content: e => e.PublicatIn,
                                    align: 'left',
                                    width: '10%',
                                },
                                {
                                    name: 'Optiuni',
                                    content: e => 'Optiuni',
                                    align: 'left',
                                    width: '20%'
                                },
                            ]}
                            onStergeClick={this.onStergeClick}
                            onMutaJos={this.onMutaJos}
                            onMutaSus={this.onMutaSus}
                            data={this.state.articoleExpresieCustomLst}
                            count={1}
                            initialPage={0}
                            dataKey="IdArticol"
                        />

                    </div>

                    : null}

                <br /><br />

                {this.state.expresieCustomExpanded == true ?
                    <div>
                        <h5>Adauga Articol Expresie : `{this.state.expresieSelectata}`</h5>
                        <div>
                            <input
                                type='number'
                                className='form-control'
                                pattern='[0-9]{0,5}'
                                placeholder='Articol Id...'
                                onChange={this.handleIdArticolNouChange}
                            />
                            <Button style={{ marginTop: '5px', marginBottom: '5px' }} variant="contained" color="primary" size="medium" onClick={() => this.onAdaugaArticolNou()} > Adauga</Button>
                        </div>

                        <div style={{ marginLeft: '87%', marginTop: '20px', marginBottom: '20px' }}>
                            <Button variant="contained" color="primary" size="medium" onClick={() => this.onSalveazaModificarile()} > Salveaza Modificarile</Button>
                        </div>
                    </div>
                    : null}
                {console.log('this.state', this.state)}

                <Snackbar open={this.state.snackBarVisible} autoHideDuration={6000} onClose={this.onCloseSnackBar}>
                    <Alert severity="success" className="alert" style={{ backgroundColor: '#f8d7da', height: '55px', textAlign: 'center', fontSize: '18px' }} >
                        Modificari realizate cu succes!
                     </Alert>
                </Snackbar>
            </div>
        );
    }
}

export default AdministrareVizualizareArtOrdCustom;