import React from "react";
import SearchBar from './searchbar/SearchBar'
import './Header.css';
import RequestLegisArticol from "../../requests/RequestLegisArticol";
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expresie: '',
            articolePePagina: 10,
            cautaInTitlu: true,
            cautaInContinut: false,
            pagina: 1,
            isLoading: false,
            snackBarVisible: false,
            enterKey: false
        }
        this.onExpresieChanged = this.onExpresieChanged.bind(this);
        this.onEnterKey = this.onEnterKey.bind(this);
    }

    onExpresieChanged(newValue) {
        this.setState({ expresie: newValue });
    }


    async onEnterKey(newValue) {
        this.setState({ enterKey: newValue });
        this.cautaClick();
    }


    async cautaClick() {
        this.props.onClickCauta(true); //isLoading = true
        this.props.onArticoleFiltrateSumarChanged(await RequestLegisArticol(this.state));
        this.props.onClickCauta(false);//isLoading = true
    }

    openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    render() {
        return (
            <div className="app-header" >
                <Link to="" onClick={() => this.openInNewTab('https://ilegis.ro')} style={{ float: 'right', marginTop: '10px' }}>

                    <button type="button" className="btn btn-secondary" >
                        Logare iLEGIS
                        </button>

                </Link>

                <Snackbar open={this.state.snackBarVisible} autoHideDuration={6000} onClose={this.onCloseSnackBar}>
                    <Alert role="alert" className="alert alert-danger" style={{ backgroundColor: '#f8d7da', height: '55px', textAlign: 'center', fontSize: '18px' }} >
                        <b style={{ color: 'black' }}>Pentru a avea acces la aceasta optiune este nevoie sa va inregistrati!</b>
                    </Alert>
                </Snackbar>

                <div className="row" >
                    <div className="form-group search-bar">
                        <SearchBar onExpresieChanged={this.onExpresieChanged} onEnterKey={this.onEnterKey} />

                        <ul className="nav justify-content-end" >

                            <li className="nav-item">


                                <FormControl component="fieldset" >
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox name="cbxTitlu" checked={this.state.cautaInTitlu} onChange={e => {
                                                this.setState({ cautaInTitlu: e.target.checked, cautaInContinut: e.target.checked == true ? false : true, snackBarVisible: false });
                                            }}
                                                color="default"
                                            />}
                                            label="Cauta In Titlu"
                                        />
                                    </FormGroup>
                                </FormControl>
                                <FormControl component="fieldset" >
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox name="cbxContinut" checked={this.state.cautaInContinut} onChange={e => {
                                                this.setState({ cautaInContinut: e.target.checked, cautaInTitlu: e.target.checked == true ? false : true, snackBarVisible: e.target.checked ? true : false });

                                            }}

                                                color="default"
                                            />}
                                            label="Cauta in Continut"
                                        />
                                    </FormGroup>
                                </FormControl>

                                <button type="button" className="btn btn-secondary" checked={this.state.cautaInContinut} onClick={() => this.cautaClick()}  >
                                    Cauta
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default Header;
