import axios from 'axios';

/**
 * Wrapper around axios, for our services.
 *
 * NOTE! All asynchronous services take SUCCESS & ERROR callbacks, in addition to other things they might reqiure.
 * Synchronous ones do not take callbacks, since they return data directly.
 */
const RequestLegis = (function (config) {
    if (!config) config = {};

   
    const handleNotLoggedIn = () => window.location = "/login";

    const base = {
        url: config.baseUrlOverride == null ? "http://91.210.80.42:5432" : config.baseUrlOverride,
        head: {
            // cheie DumaGas: RHVtYUdBUzpBdXRlbnRpZmljYXJlQU5BRjIwMTYh
            // cheie admin: QXV0ZW50aWZpY2FyZUFkbWluQ29tcGFueURhdGFBcGkyMDE3IQ==
            'API_KEY': 'Q29tcGFueWRhdGFTb2Z0d2FyZTIwMTchQXBp',
           
            // auth admintool: V2Vic2l0ZTpEYXRlRmlybWVXZWJzaXRlMjAxNyE=
            'Authorization': 'Basic V2Vic2l0ZTpEYXRlRmlybWVXZWJzaXRlMjAxNyE=',
        }
    };

    if (config.logRequest) {
        axios.interceptors.request.use(cfg => {
            console.log(cfg);
            return cfg;
        });
    }

    if (config.logResponse) {
        axios.interceptors.response.use(res => {
            return res.data;
        });
    } else {
        axios.interceptors.response.use(res => res.data);
    }

    return {

        //Articole
        async getArticolCautareInContinut(expresie,pagina,articolePePagina){
            const head = {...base.head};
            return await axios.get(`${base.url}/api/articolSumar?CautaInArticol=true&pagina=${pagina}&Expresie=${expresie}&ArticolePePagina=${articolePePagina}`, {headers: head})
        },
        async getArticolCautareInTitlu(expresie,pagina,articolePePagina){
            const head = {...base.head};
            return await axios.get(`${base.url}/api/articolSumar?CautaInTitlu=true&pagina=${pagina}&Expresie=${expresie}&ArticolePePagina=${articolePePagina}`, {headers: head});
        },
        async getArticolContinutById(articolId){
            console.log('url=',`${base.url}/api/articolContinut?articolId=${articolId}`);
            const head = {...base.head};
            return await axios.get(`${base.url}/api/articolContinut?articolId=${articolId}`, {headers: head});
        },
        async getArticoleNoi(pagina,articolePePagina){
            const head = {...base.head};
            return await axios.get(`${base.url}/api/articoleNoi?Pagina=${pagina}&ArticolePePagina=${articolePePagina}`, {headers: head})
        },

        //Administrare
        async getExpresiiCustom(pagina,articolePePagina){
            const head = {...base.head};
            return await axios.get(`${base.url}/api/expresiiCustom?pagina=${pagina}&ArticolePePagina=${articolePePagina}`, {headers: head})
        },

        async getArticoleExpresieCustom(idArticolCustom){
            const head = {...base.head};
            return await axios.get(`${base.url}/api/articoleExpresieCustom?idArticolCustom=${idArticolCustom}`, {headers: head})
        },

        async getArticolById(idArticol){
            const head = {...base.head};
            return await axios.get(`${base.url}/api/articolById?idArticol=${idArticol}`, {headers: head})
        },



        //Post ArticoleExpresie
        async PostArticoleExpresie(props){
            const head = {...base.head};

            return await axios
            ({
                method: 'post',
                url: `${base.url}/api/articoleExpresieCustom`,
                headers: head,
                data:{ 
                    ExpresieId : props.expresieId,
                    ArticoleExpresieCustom : props.articoleExpresieCustom
                }
            });
        },


        //Post ExpresieCustomNoua
        async PostExpresieCustom(props){
            const head = {...base.head};

            return await axios
            ({
                method: 'post',
                url: `${base.url}/api/expresieCustom`,
                headers: head,
                data:{ 
                    ExpresieCustom : props.expresieCustom
                }
            });
        },


        //New Account
        async PostNewAccount(account){
            const head = {...base.head};

            return await axios
            ({
                method: 'post',
                url: `${base.url}/api/legisContNou`,
                headers: head,
                data:{ 
                    NumeSocietate : account.numeSocietate,
                    PersoanaDeContact: account.persoanaDeContact,
                    Localitate : account.localitate,
                    User : account.user,
                    Email : account.email,
                    Telefon : account.telefon,
                    Password : account.password
                }
            });
        },

        async PostNewContact(contact){
            const head = {...base.head};

            return await axios
            ({
                method: 'post',
                url: `${base.url}/api/legisContact`,
                headers: head,
                data:{ 
                    Email : contact.email,
                    Subiect: contact.subiect,
                    Telefon : contact.telefon,
                    Mesaj : contact.mesaj
                }
            });
        },

        async PostNewComanda(comanda){
            const head = {...base.head};

            return await axios
            ({
                method: 'post',
                url: `${base.url}/api/legisComanda`,
                headers: head,
                data:{ 
                    DetaliiPachet : comanda.detaliiPachet,
                    TipPachet : comanda.tipPachet,
                    NumeSocietate : comanda.numeSocietate,
                    CodFiscal: comanda.codFiscal,
                    RegistrulComertului : comanda.registrulComertului,
                    ContIBAN : comanda.contIBAN,
                    Banca : comanda.banca,
                    Localitate: comanda.localitate,
                    Judet : comanda.judet,
                    Adresa : comanda.adresa,
                    Telefon : comanda.telefon,
                    Email : comanda.email,
                    PersoanaDeContact : comanda.persoanaDeContact,
                }
            });
        }


    };
})
({
    //http://91.210.80.42:5432/
    //http://localhost:52903/
    //http://api.datefirme.ro
    baseUrlOverride: "https://api.legis.ro",
    logRequest: false,
    logResponse: false
});

export default RequestLegis;