import './DevinPartenerPage.css';
import React from 'react';
class DevinPartenerPage extends React.Component {

    constructor(props) {
        super(props);


    }


    render() {

        return (
            <div className="devinPartener">
                <div className="devinPartenerInfo">
                    <span>
                        <b style={{ color: '#42A0CE' }}  >Devino Partener</b>

                    </span>
                    <p>Căutăm parteneri de distribuție alături de care să ne indreptăm către un obiectiv comun: dezvoltarea permanentă a afacerii.</p>

                    <br />
                    <div>
                        <div className="benefits" />
                        <b>Avantaje</b>

                        <div className="row">
                            <span className="category">  Nu trebuie să-ti dedici timpul integral! Lucreaza când poți!.</span>
                        </div>
                        <div className="row">
                            <span className="category">  Promovezi un produs de încredere si recunoscut pe piață.</span>
                        </div>
                        <div className="row">
                            <span className="category">  Un client adus de tine, iti aduce un <strong>comision recurent lunar</strong>(atât timp cât clientul este activ, fără restrictii).</span>
                        </div>
                        <div className="row">
                            <span className="category">  Fă doar o recomandare și ne ocupăm noi de incheierea contractului, clientul este al tău.</span>
                        </div>
                    </div>



                    <br />
                    <br />
                    <br />
                    <div>
                        <div className="deal" />
                        <b>Devin Partener</b>
                        <div className="row">
                            <span className="category"> Dacă dorești să devii partener iLEGIS contactează-ne să facem cunoștință și să creăm împreună o echipă.</span>
                        </div>
                    </div>

                    <br />
                    <br />
                    <br />
                    <div>
                        <div className="emailDevinPartener" />
                        <b>Contact Email</b>
                        <div className="row">
                            <span className="category">  Trimite-ne un Email pe lemeni@legis.ro</span>
                        </div>
                    </div>

                </div>

            </div>
        );
    }
}

export default DevinPartenerPage;