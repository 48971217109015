import React from 'react';
import './SearchBar.css';
import Autosuggest from 'react-autosuggest';
import RequestLegisArticol from "../../../requests/RequestLegisArticol";

const sugestiiPredefinite = [
    {
        Titlu: 'Sugestii de cautare...',
    },
    {
        Titlu: 'CODUL CIVIL din 17 iulie 2009 (Legea nr. 287/2009)',
    },
    {
        Titlu: 'CODUL DE PROCEDURA CIVILA din 1 iulie 2010 (Legea nr. 134)',
    },
    {
        Titlu: 'CODUL MUNCII din 24 ianuarie 2003 (*republicat*) (Legea nr. 53/2003)',
    }
];


class SearchBar extends React.Component {

    constructor() {
        super();
        this.state = {
            expresie: '',
            articoleFiltrateSumar:[],
            suggestions: this.getSuggestions(''),
            isLoading: false,
        };

        this.onChange = this.onChange.bind(this);
        this.onSuggestionsUpdateRequested = this.onSuggestionsUpdateRequested.bind(this);
        this.shouldRenderSuggestions = this.shouldRenderSuggestions.bind(this);
        this.escapeRegexCharacters = this.escapeRegexCharacters.bind(this);
        this.getSuggestions = this.getSuggestions.bind(this);
    }


    escapeRegexCharacters(str) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }
    
     getSuggestions(value) {
         
        const escapedValue = this.escapeRegexCharacters(value.trim());
        const regex = new RegExp('^' + escapedValue, 'i');
        
        var sugestiiLst = sugestiiPredefinite.filter(sp => regex.test(sp.Titlu));
     
        if(this.state != null && this.state.articoleFiltrateSumar !=null && this.state.articoleFiltrateSumar.ArtSumarLst != null && this.state.articoleFiltrateSumar.ArtSumarLst.length > 0)
        {
            sugestiiLst = this.state.suggestions;
        }
        return sugestiiLst;
    }
    
     getSuggestionValue(suggestion) 
     {
        var suggestionTxt = suggestion.Titlu == null ? suggestion : suggestion.Titlu;
        return suggestionTxt.replace('Sugestii de cautare...',sugestiiPredefinite[1].Titlu);
    }
    
     renderSuggestion(suggestion) {
        return (
            <span>{suggestion.Titlu == null ? suggestion : suggestion.Titlu}</span>
        );
    }


     async handleExpresieChangedRequest()
     {
        try {
            this.setState({
                isLoading: true
            })
            
            var response = await RequestLegisArticol({
                expresie: this.state.expresie,
                pagina: 1,
                totalPagini: 1,
                cautaInTitlu: true,
                cautaInContinut: false,
                articolePePagina: 10
            });

            if (response != null) {
                this.setState({
                    articoleFiltrateSumar: response,
                })

                if(this.state !=null && this.state.articoleFiltrateSumar !=null && this.state.articoleFiltrateSumar.ArtSumarLst !=null && this.state.articoleFiltrateSumar.ArtSumarLst.length > 0){
                 
                    var artLst = this.state.articoleFiltrateSumar.ArtSumarLst.map((item) => item.Titlu)
                    var artLstFiltrat = [];
                    /*if(artLst !=null && artLst.length > 0){
                        artLstFiltrat = this.state.articoleFiltrateSumarLst
                        artLstFiltrat.push(...artLst);
                        this.setState({
                            articoleFiltrateSumarLst : artLstFiltrat
                        })
                        
                        console.log('this.state.articoleFiltrateSumarLst=',this.state.articoleFiltrateSumarLst);
                    }*/
                    if(artLst !=null && artLst.length > 0){
                        this.setState({
                            suggestions: artLst,
                        })
                    }
                }
            }
            this.setState({
                isLoading: false
            })

        } catch (e) {
            console.log('error=', e)
        }
    }


    handleExpresieChanged = (event) => {
        event.preventDefault();
        this.state.expresie = event.target.value;
        this.props.onExpresieChanged(this.state.expresie);
    }

    handleExpresieChangedAutoSuggest = (event) => {
        event.preventDefault();
        this.state.expresie = event.target.textContent;
        this.props.onExpresieChanged(this.state.expresie);
    }

    handleOnKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.props.onEnterKey(true);
        }
    }

    async onChange(event, { newValue, method }) {
        
        this.state.expresie = newValue;
        var titluPredefinit = sugestiiPredefinite.filter(s=>s.Titlu == newValue);
            if(titluPredefinit == null || titluPredefinit.length == 0 && newValue.length > 3){
                await this.handleExpresieChangedRequest();
            }
        this.props.onExpresieChanged(this.state.expresie);
    }

    onSuggestionsUpdateRequested({ value }) {
        this.setState({
            suggestions: this.getSuggestions(value),
        });
    }

    onSuggestionHighlighted({ value }) {
        console.log('here')
    }

    shouldRenderSuggestions() {
        return true;
    }

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };


    render() {

        const { expresie, suggestions } = this.state;
        const inputProps = {
            placeholder: "Cauta in baza de date LEGIS",
            value : expresie,
            onChange: this.onChange,
            onKeyPress: this.handleOnKeyPress
        };
        return (
            <div>
                {/*
                <input
                    className="form-control"
                    type="text"
                    placeholder="Cauta in baza de date LEGIS"
                    onChange={this.handleExpresieChanged}
                    onKeyPress={this.handleOnKeyPress}
                /> */}

                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionHighlighted={this.onSuggestionHighlighted}
                    onSuggestionsUpdateRequested={this.onSuggestionsUpdateRequested}
                    shouldRenderSuggestions={this.shouldRenderSuggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    onKeyPress={this.handleOnKeyPress}
                    inputProps={inputProps} />
            </div>
        );
    }
}

export default SearchBar;
