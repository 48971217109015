import React from 'react';
import './CreazaContPage.css';
import RequestLegisAccount from '../../../requests/RequestLegisAccount';
import swal from '@sweetalert/with-react';

class CreazaContPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showRegister: false,
        };

        this.onInputchange = this.onInputchange.bind(this);
        this.registerUser = this.registerUser.bind(this);
    }


    onInputchange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }


    async registerUser() {
        const errorMessages = [];

        var errMsg = false;
        if (this.state.persoanaDeContact ==  null || this.state.persoanaDeContact =='') 
            errMsg = true;
        if (this.state.localitate ==  null || this.state.localitate =='') 
            errMsg = true;
        if (this.state.email ==  null || this.state.email =='') 
            errMsg = true;
        if (this.state.telefon ==  null || this.state.telefon =='') 
            errMsg = true;
        if(errMsg)
            errorMessages.push('Completati toate Campurile');

            
        if(this.state.email != null && !this.state.email.includes('@'))
            errorMessages.push('Email invalid!');

        if(isNaN(this.state.telefon) && this.state.telefon !=null)
             errorMessages.push('Numar telefon invalid!');

        if(this.state.telefon != null && this.state.telefon.length != 10)
            errorMessages.push('Numar telefon invalid!');

        this.setState({ errorMesages: errorMessages });

        if (errorMessages.length === 0) {

            //POST to WebApi
            await RequestLegisAccount({
                creazaUser: true,
                numeSocietate: this.state.numeSocietateSauInstitutie,
                persoanaDeContact: this.state.persoanaDeContact,
                localitate: this.state.localitate,
                email: this.state.email,
                telefon: this.state.telefon,
            });
            
            //curata state
            this.setState({
                showRegister: false,
                numeSocietateSauInstitutie: '',
                persoanaDeContact:'',
                localitate: '',
                email: '',
                telefon: '',
            })

            swal(
                <div style={{textAlign:'justify'}} >
                  <div>
                  <h3 className="check"></h3>        
                  </div>
                  <div style={{margin:'5%'}}>
                  <strong>Va multumim pentru inregistrare.</strong>
                  <p>Veti fi contactat de operator pentru datele contului dumneavoastra.</p>
                  </div>
                </div>
              )

        }
    }

    render() {

        return (
            <div>

                <div className="creazaCont">
                    <div className="contNou"/>
                        <br/>
                    <h4>Inregistrare Cont</h4>
                    
                    <br/>
                    <input type="text" className="input creaza-cont" name="numeSocietateSauInstitutie" value={this.state.numeSocietateSauInstitutie} onChange={this.onInputchange} placeholder="*Nume societate sau institutie:" required />

                    <br/>
                    <input type="text" className="input creaza-cont" name="persoanaDeContact" value={this.state.persoanaDeContact} onChange={this.onInputchange} placeholder="*Persoana de contact:" required />
                    <p />
                    <input type="text" className="input creaza-cont" name="localitate" value={this.state.localitate} onChange={this.onInputchange} placeholder="*Localitate:"
                        required
                    />

                    <p />
                    <input type="email" className="input creaza-cont" name="email" value={this.state.email} onChange={this.onInputchange} placeholder="*Email:" required />
                    <p />
                    <input type="number" className="input creaza-cont" name="telefon" value={this.state.telefon} onChange={this.onInputchange} placeholder="*Telefon" />
                    <p />
                    <p />

                    <div >
                        <input className="modals-check" type="checkbox" required />&nbsp;
                                    Sunt de acord ca adresa de email si numarul de telefon să fie folosite pentru a primi serviciile si informările Legis.
                                </div>
                                
                    {this.state.errorMesages != null ? this.state.errorMesages.map((message) => <p className="modals-message" >{message}</p>) : null}
                    <br/>
                    <button type="submit" className="btn btn-secondary" onClick={() => this.registerUser()}>Trimite Cererea</button>
                    <div className="clearfix" />
                    
                </div>
            </div>
        );
    }
}

export default CreazaContPage;