import React from 'react';
import Header from './header/Header';
import Body from './body/Body';
import './App.css';
import '../components/header/nav/Nav.css';
import ArticolNou from '../components/body/articolNou/ArticolNou';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import Contact from './body/contact/Contact';
import ContactPage from './body/contact/ContactPage';
import DevinPartener from './body/parteneri/DevinPartener';
import DevinPartenerPage from './body/parteneri/DevinPartenerPage';
import ArticolDetailsPage from '../components/body/articol/ArticolDetailsPage';
import AdministrareArticole from '../components/body/administrare/AdministrareArticole';
import CreazaContPage from './body/creazaCont/CreazaContPage';
import ComandaLegis from './body/comanda/ComandaLegis';

class AppModule extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            articoleFiltrateSumar: null,
            isLoading: false,
            showContact: false,
            showDevinPartener: false,
            isAccordionModuleOpen: false,
        }
        this.onArticoleFiltrateSumarChanged = this.onArticoleFiltrateSumarChanged.bind(this);
        this.onClickCauta = this.onClickCauta.bind(this);
        this.onAccordionModuleChanged = this.onAccordionModuleChanged.bind(this);
        this.showContact = this.showContact.bind(this);
        this.showDevinPartener = this.showDevinPartener.bind(this);
    }

    onArticoleFiltrateSumarChanged(newValue) {
        this.setState({ articoleFiltrateSumar: newValue });
    }

    onClickCauta(newValue) {
        this.setState({ isLoading: newValue, isAccordionModuleOpen: false });
    }


    showContact(val) {
        this.setState({
            showContact: val
        });
    };

    showDevinPartener(val) {
        this.setState({
            showDevinPartener: val
        });
    };

    onAccordionModuleChanged(val) {
        this.setState({
            isAccordionModuleOpen: val
        });
    };

    openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    render() {
        return (

            <div>

                {this.state.isLoading == true ? <div className="loading">
                    <p className="loading-text">Vă rugăm, asteptaţi. Datele se încarcă</p>
                </div> : null}
                <Router>
                    {!window.location.toString().includes('articolDetails') && !window.location.toString().includes('adm/app') ?
                        <div className="container-fluid app"  >

                            <Header onArticoleFiltrateSumarChanged={this.onArticoleFiltrateSumarChanged} onClickCauta={this.onClickCauta} />
                            <div >

                                <ul className="nav justify-content-end">
                                    {console.log('window.location.toString()',window.location.toString())}
                                  
                                    <li className="nav-item">
                                        <Link to="/" >

                                            <button type="button" className="btn btn-secondary" >
                                                Home
                                            </button>

                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link to="" onClick={() => this.openInNewTab('http://portal.datefirme.ro')}>

                                            <button type="button" className="btn btn-secondary" >
                                                Verificare Firme
                                            </button>

                                        </Link>
                                    </li>


                                    <li className="nav-item">
                                        <Link to="/devinoPartener"  >

                                            <button type="button" className="btn btn-secondary" >
                                                Devino partener
                                            </button>

                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link to="/contact">

                                            <button type="button" className="btn btn-secondary" >
                                                Contact
                                            </button>
                                        </Link>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        : null}
                    {this.state.showContact == true ? <Contact showContact={this.showContact} /> : null}
                    {this.state.showDevinPartener == true ? <DevinPartener showDevinPartener={this.showDevinPartener} /> : null}

                    <Switch>
                        <Route path="/articoleNoi" component={ArticolNou}></Route>
                        <Route path="/devinoPartener" component={DevinPartenerPage} />
                        <Route path="/contact" component={ContactPage} />
                        <Route path="/creazaCont" component={CreazaContPage} />
                        <Route path="/comanda" component={ComandaLegis} />
                        <Route path="/adm/app" component={AdministrareArticole} />
                        <Route path="/articolDetails/:titlu" component={ArticolDetailsPage} />

                        <Route path="/">
                            <div>
                                <Body articoleFiltrateSumar={this.state.articoleFiltrateSumar}
                                    onArticoleFiltrateSumarChanged={this.onArticoleFiltrateSumarChanged}
                                    onAccordionModuleChanged={this.onAccordionModuleChanged}
                                    isAccordionModuleOpen={this.state.isAccordionModuleOpen} />
                            </div>
                        </Route>
                    </Switch>
                </Router>
            </div>
        );
    }
}

export default AppModule;

