
import React from 'react';


export default function HtmlPrezentare() {
  const greeting = `<!DOCTYPE html>
  <!-- saved from url=(0031)http://legis.datefirme.ro/page/ -->
  <html><head><meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <link rel="stylesheet" href="./legis.datefirme.ro_files/all.css">
    <style>
      body {background-color: white; font-family: Arial;}
      h1   {color: black; font-size:60px;}
      table {
        border-collapse: collapse;
        table-layout: fixed;
      }
      table td, table th {
        border: 0px solid black;
      }
      .titlu {color: black; font-weight: bold;}
      .subtitlu {color: red; font-weight: bold; font-size: 14px;}
      .detalii {color: gray; font-size: 13px; padding-top: 10px;padding-bottom: 10px;}
      
      .zoom {
        background-color: white;
        transition: transform .2s;
        margin: 0 auto;
      }
  
      .zoom:hover {
        -ms-transform: scale(1.5); /* IE 9 */
        -webkit-transform: scale(1.5); /* Safari 3-8 */
        transform: scale(1.5); 
      }
    </style>
  </head>
  <body>
    <center>
      <table style="width 1360px;">
        <tbody>
        <tr>
          <td style="width:430px;">
            <div style="width: 43px"></div>
          </td>
          <td colspan="2" style="text-align:left; padding-left:220px;">
            <table>
              <tbody><tr>
                <td style="padding-right:10px;">
                  <div>
                    <i class="fas fa-hand-point-up fa-3x" style="color:#31B095;"></i>
                  </div>
                </td>
                <td>
                  &nbsp;
                  <div class="zoom" style="width:600px;">
                    <div class="titlu">FORMA ACTUALIZATĂ A ACTELOR LA DATA ALEASĂ DE UTILIZATOR.</div>
                    <div class="subtitlu">NORME DE APLICARE LA NIVEL DE ALINIAT SAU ARTICOL</div>
                    <div class="detalii">Permite utilizatorului să poată compara formele actelor la momente alese de acesta. 
                      <br>Instrument performant extrem de util si facil.</div>
                  </div>
                </td>
              </tr>
            </tbody></table>
          </td>
        </tr>
        <tr>
          <td rowspan="2" style="width:430px; vertical-align:top; padding-top:32px;">
            <table>
              <tbody><tr>
                <td style="height:170px; vertical-align:top;">
                  <div class="zoom">
                    <div class="titlu" style="text-align:right;">APLICATII CORELATE</div>
                    <div class="subtitlu" style="text-align:right;">MONITORIZAREA PERSOANE JURIDICE</div>
                    <div class="detalii">
                      <br>• Monitorizarea in BPI
                      <br>• Cand se impune, monitorizarea in toate sursele a personelor juridice: ANAF, Monitorul Oficial, Reg Comerţului, Portal Justiţie, AEGRM, Centrala Incidentelor de Plată
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td style="height:170px; vertical-align:top;">
                  <div class="zoom">
                    <div class="titlu" style="text-align:right;">APLICATII CORELATE</div>
                    <div class="subtitlu" style="text-align:right;">JURISTPRO - MANAGEMENT DOSARE DE INSTANTA</div>
                    <div class="detalii">• Alerte SMS si E-MAIL la orice modificare apare in dosarele de instanţă
                      <br>• Calendar de evenimente
                      <br>• Rapoarte de activitate
                      <br>• Automatizarea introducerii de dosare noi
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td style="height:100px; vertical-align:top;">
                  <div class="zoom">
                    <div class="titlu" style="text-align:right;">AFIŞARE INTUITIVĂ</div>
                    <div class="subtitlu" style="text-align:right;">INFORMATII ARTICOL, VIZUALIZARE IN PARALEL</div>
                    <div class="detalii">• ușor de urmărit, claritate a expunerii;
                      <br>• legături de modificare si interpretare, istoric modificări;
                      <br>• legături cu jurisprudenţa la nivel de articol;
                      <br>• anexele, tabelele de importanţă crescută sunt editabile;
                      <br>• actele pot fi vizualizate in ferestre paralele cu poziţionare la nivel de articol;
                    </div>
                  </div>
                </td>
              </tr>
            </tbody></table>
          </td>
          <td style="vertical-align:top;">
            <img src="http://legis.ro/page/logo.png">
          </td>
          <td style="width:430px; vertical-align:top; padding-top:26px;">
            <table>
              <tbody><tr>
                <td style="height:170px; vertical-align:top;">
                  <div class="zoom">
                    <div class="titlu">MOTOR DE CĂUTARE ULTRAPERFOMANT</div>
                    <div class="subtitlu">CĂUTARE DE TIP GOOGLE DUPĂ ACTE, CUVINTE, EXPRESII</div>
                    <div class="detalii">In bara de căutare se caută dupa datele dorite:
                      <br>• dupa numărul actului, tipul actului, anul actului 
                      <br>• sau direct expresii si cuvinte
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td style="height:160px;vertical-align:top;">
                  <div class="zoom">
                    <div class="titlu">SUPORT ŞI SERVICE CLIENŢI</div>
                    <div class="detalii">• Manual de utilizare
                      <br>• Tutoriale video pentru funcţiile importante.
                      <br>• Suport telefonic sau remote oferit de operatori umani.
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="zoom">
                    <div class="titlu">MONITORIZAREA ACTELOR DE INTERES</div>
                    <div class="subtitlu">SETĂRI DE LISTE DE ACTE SPECIFICE</div>
                    <div class="detalii">Posibilitatea de a monitoriza actele de interes. Alerte Email si cont aplicaţie cand se modifică un act.
                      <br>Posibilitatea de a monitoriza domenii predefinite sau liste predefinite.
                    </div>
                  </div>
                </td>
              </tr>
            </tbody></table>
          </td>
        </tr>
        <tr>
          <td colspan="2" style="text-align:left; padding-left:220px;">
            <i class="fas fa-users fa-3x" style="color:#31B095; padding-left:20px;"></i>
            <div class="zoom" style="width:700px;">
              <div class="titlu">24 DE MODULE CONFIGURABILE</div>
              <div class="subtitlu">CONFORM CERINŢELOR CLIENTULUI</div>
              <div class="detalii">
                <table style="width:100%;">
                  <tbody><tr>
                    <td style="vertical-align:top;">
                      • Legislaţie romȃnească
                      <br>• Jurisprudenţă romȃnească
                      <br>• Practica Judiciară
                      <br>• Jurisprudenţa CNSC
                      <br>• Legislaţie Europeană
                      <br>• Jurisprudenţa Europeană
                      <br>• Jurisprudenţa CEDO
                      <br>• Calendar evenimente fiscale
                    </td>
                    <td style="vertical-align:top;">
                      • Corespondenţă Coduri
                      <br>• Corespondenţa Fiscală
                      <br>• Corespondenţă Regulamente 
                      <br>• Management achiziţii publice
                      <br>• Monitorizări Reviste
                      <br>• Doctrine
                      <br>• Sinteze Economice
                      <br>• Modele tip
                    </td>
                    <td style="vertical-align:top;">
                      • Contracte colective
                      <br>• Autorizaţii și proceduri
                      <br>• Dicţionare
                      <br>• Definiţii si abrevieri
                      <br>• Coduri și Clasificări
                      <br>• Monitorizare Firme
                      <br>• Management dosare de Instanţă
                    </td>
                  </tr>
                </tbody></table><table>
              
            
          
        
      </table>
    
  
  </div></div></td></tr></tbody></table></center></body></html>`
 
  return greeting;
}
 