import React from "react";
import './ArticolSumar.css';
import ReactPaginate from 'react-paginate';
import RequestLegisArticol from "../../../requests/RequestLegisArticol";
import Highlighter from "react-highlight-words";
import { Link } from 'react-router-dom';
class ArticolSumar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            expresie: this.props.articoleFiltrateSumar.Expresie,
            articoleFiltrateSumar: this.props.articoleFiltrateSumar,
            pagina: 1,
            perPage: 10,
            totalPagini: 1,
            cautaInTitlu: true,
            cautaInContinut: false,
            isLoading: false,
            pageChanged: false,
        };
        this.handlePageChange = this.handlePageChange.bind(this)
    }


    async handlePageChange(data) {

        try {

            this.setState({
                isLoading: true
            })
            var response = await RequestLegisArticol({
                expresie: this.props.articoleFiltrateSumar.Expresie,
                pagina: data.selected + 1,
                totalPagini: this.state.totalPagini,
                cautaInTitlu: this.state.cautaInTitlu,
                cautaInContinut: this.state.cautaInContinut,
                articolePePagina: this.state.perPage
            });

            if (response != null) {
                this.setState({
                    articoleFiltrateSumar: response,
                    isLoading: false,
                    pageChanged: true,
                    expresie: response.expresie,
                })
                this.props.onArticoleFiltrateSumarChanged(this.state.articoleFiltrateSumar);
            }

        } catch (e) {
            console.log('error=', e)
        }
    }

    render() {
        let articoleFiltrateSumar = this.props.articoleFiltrateSumar;
        if (articoleFiltrateSumar == null || articoleFiltrateSumar.ArtSumarLst == null) {
            return null;
        }
        const offset = this.state.pagina == 1 ? 0 : this.state.pagina * this.state.perPage;
        const pageCount = Math.ceil(articoleFiltrateSumar.TotalArticole / this.state.perPage);
        console.log('response=', this.props)
        return (

            <div className="articolSumar col-xs-11 col-md-11 col-lg-11" >
                {articoleFiltrateSumar.ArtSumarLst.length > 0 ?
                    <div className="company-dossiers-list">
                        {articoleFiltrateSumar.ArtSumarLst.slice(offset, offset + this.state.perPage)
                            .map((element, index) =>
                                <div key={'ArticolSumar' + index} >
                                    <div className="secondary-address">
                                        {localStorage.getItem('admin') != null ?
                                            <div className="row invoicing-address-content">
                                                <div className="pull-left information-component" >
                                                    <span className="separator " />
                                                    <span className="category">ArticolId(Only Admin*): </span>
                                                      <span className="value">{element.Id ? element.Id : '-'}</span>
                                                </div>
                                            </div> : null
                                        }
                                        <div className="row invoicing-address-content">
                                            <div className="pull-left information-component" >
                                                <span className="separator " />
                                                <span className="category">Tip Act: </span>
                                                <Link target={"_blank"} to={`/articolDetails/${element.Titlu.replace(/\//g, '-').replace(/\s+/g, '-')}-${element.Id}`}  >{element.TipArticol ? element.TipArticol : 'Vezi Act'}</Link>
                                            </div>
                                        </div>
                                        <div className="row invoicing-address-content">
                                            <div className="pull-left information-component">
                                                <span className="separator" />
                                                <span className="category">Data Emiterii: </span>
                                                <span className="value">{element.TitluData ? element.TitluData : '-'}</span>
                                            </div>
                                        </div>
                                        <div className="row invoicing-address-content">
                                            <div className="pull-left information-component">
                                                <span className="separator" />
                                                <span className="category">Titlu: </span>
                                                <span className="value">
                                                    <Highlighter
                                                        searchWords={this.state.articoleFiltrateSumar != null && this.state.articoleFiltrateSumar.ExpresieRootAStr != null ? this.state.articoleFiltrateSumar.ExpresieRootAStr : ''}
                                                        textToHighlight={element.Titlu != null ? element.Titlu : '-'}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row invoicing-address-content">
                                            <div className="pull-left information-component">
                                                <span className="separator" />
                                                <span className="category">Emitent: </span>
                                                <span className="value">{element.Emitent != null ? element.Emitent : '-'}</span>
                                            </div>
                                        </div>

                                        <div className="row invoicing-address-content">
                                            <div className="pull-left information-component">
                                                <span className="separator" />
                                                <span className="category">Publicat in: </span>
                                                <span className="value">{element.PublicatIn != null ? element.PublicatIn : '-'}</span>
                                            </div>
                                        </div>

                                        <div className="row invoicing-address-content">
                                            <div className="pull-left information-component">
                                                <span className="separator" />
                                                <span className="category">Data intrarii in vigoare: </span>
                                                <span className="value">{element.DataIntrariiInVigoare != null ? element.DataIntrariiInVigoare : '-'}</span>
                                            </div>
                                        </div>
                                        &nbsp;

                                    </div>
                                </div>)
                        }
                    </div> : null
                }
                <ReactPaginate
                    previousLabel={"← Previous"}
                    nextLabel={"Next →"}
                    pageCount={pageCount}
                    onPageChange={this.handlePageChange}
                    containerClassName={"pagination"}
                    previousLinkClassName={"pagination__link"}
                    nextLinkClassName={"pagination__link"}
                    disabledClassName={"pagination__link--disabled"}
                    activeClassName={"pagination__link--active"}
                />

                {this.state.isLoading == true ? <div className="loading">
                    <p className="loading-text">Vă rugăm, asteptaţi. Datele se încarcă</p>
                </div> : null}
            </div>
        );
    }
}

export default ArticolSumar;
