import React from 'react';


import './../legisPrezentare/DespreNoi.css';

class DemoDetails extends React.Component {

    componentDidMount = () => {
        if (this.props.location) {
            let { title } = this.props;
            const { pathname } = this.props.location;
        }
    }

    render() {
        let { title, body } = this.props;
        return (
            <div className="demoDetails container">
                <div className="new-head" style={{ textAlign: "center" }}>
                    <h2>{title}</h2>
                </div>
                <hr />

                <div className="details-monitoring">


                <div>
				<p style={{textAlign:'justify'}}><strong>iLegis</strong> – Aplicatia legislativa a CTCE Piatra-Neamt este cea mai completa si corecta baza de date legislativa din Romania, garantie oferita prin castigarea procedurii de achizitie publica pentru 
                Proiectul “Implementarea portalului N-Lex” lansata de catre Ministerul Justitiei in numele Guvernului Romaniei in vederea interconectarii legislatiei
                Romaniei cu portalul legislativ oficial al Uniunii Europene, ca o obligatie care revenea statului roman prin Strategia Lisabona. </p>
				<ul>
					<li style={{textAlign:'justify'}}>Softul legislativ “LEGIS“ este axat in totalitate catre facilitarea accesului dumneavoastra la intreaga legislatie a ROMANIEI si la legislatia Uniunii Europene aflata in conexiune directa cu aceasta</li>
					<li style={{textAlign:'justify'}}>Acces ONLINE la legislatia romaneasca si la legislatia Uniunii Eurpone prin programul legislativ iLEGIS. Prin intermediul browser-ului de pe calculatorul dumneavoastra prin intermendiul internetului oferind un acces facil la aplicatia iLEGIS</li>
					<li style={{textAlign:'justify'}}><strong>Important!</strong> Incepand cu luna ianuarie 2020, iLegis poate fi consultat integral in limbile: Romana, Engleza, Franceza, Germana, Italiana, Maghiara, Rusa, si Bulgara</li>
				</ul>
				<h5>Modulele Aplicatiei sunt:</h5>
			</div>
                    <div>
                        <table className="demo-table">
                            <tbody>
                                
                                <tr>
                                    <td>Modul</td>
                                    <td>Descriere</td>
                                </tr>
                                <tr>
                                    <td><strong>Legislație Românească</strong></td>
                                    <td className="demo-center"><strong>Acces la peste 240.000 de acte normative, publicate in Monitorul Oficial al României partea I,partea I BIS și partea V</strong></td>
                                </tr>
                                <tr>
                                    <td><strong>Jurisprudență</strong></td>
                                    <td className="demo-center"><strong>Permite consultarea, gestionarea și actualizarea deciziilor emise de insțantele de judecată competente (Curțile de apel din țară, Inalta Curte de Casatie si Justitie, Tribunale, etc),
                                 grupate pe tematici si subtematici.</strong></td>
                                </tr>
                                <tr>
                                    <td><strong>Jurisprudență fiscală</strong></td>
                                    <td className="demo-center"><strong>Modulul prezintă spețe formulate în urma misiunii ANAF de a colecta și gestiona impozitele, taxele, contribuțiile sociale și alte sume datorate bugetului general consolidat.</strong></td>
                                </tr>
                                <tr>
                                    <td><strong>Jurisprudență CNSC</strong></td>
                                    <td className="demo-center"><strong>Modulul prezintă deciziile adoptate de Consiliul Național de Soluționare a Contestațiilor – competent să soluționeze contestațiile formulate în cadrul procedurii de atribuire, înainte de încheierea contractului, prin complete specializate.</strong></td>
                                </tr>
                                <tr>
                                    <td><strong>Jurisprudență CEDO</strong></td>
                                    <td className="demo-center"><strong>Modulul oferă acces la  Jurisprudența CURTII EUROPENE PENTRU DREPTURILE OMULUI.</strong></td>
                                </tr>
                                <tr>
                                    <td><strong>Calendar Fiscal</strong></td>
                                    <td className="demo-center"><strong>Modulul vă ajută la respectarea termenelor pentru depunearea declarațiilor, formularelor sau altor documente fiscale.</strong></td>
                                </tr>
                                <tr>
                                    <td><strong>Corespondență fiscală</strong></td>
                                    <td className="demo-center"><strong>Realizează compararea si studierea tuturor reglementarilor fiscale inca aplicabile in materie (impozitare, TVA, proceduri si obligatii fiscale, colectare venituri), cu noile reglementari aplicabile cu 01.01.2016. Realizează corespondența între noul cod fiscal și CODUL FISCAL din 22 decembrie 2003.</strong></td>
                                </tr>

                                <tr>
                                    <td><strong>Corespondență coduri</strong></td>
                                    <td className="demo-center"><strong>Realizează corespondența, la nivel de articol intre noile coduri si vechile coduri juridice. Corespondența dispozițiilor legale, care reglementează aceleasi instituții, aceeasi materie. Modulul reprezintă cel mai util instrument pentru toți practicienii dreptului.</strong></td>
                                </tr>
                                <tr>
                                    <td><strong>Corespondență regulamente instante</strong></td>
                                    <td className="demo-center"><strong>Realizează paralelismul la nivel de articol, între vechiul Regulament de ordine interioară al instanţelor judecătoreşti adoptat prin HCSM 387/2005 si actualul Regulament adoptat prin HCSM 1.375/2015.</strong></td>

                                </tr>

                                <tr>
                                    <td><strong>Ghid achizitii publice</strong></td>
                                    <td className="demo-center"><strong>Conține Informări ANAP si formulare, ce pot fi definite de utilizatorul autentificat.
                                Totodată este prezentată comparativ legislația noua si precedentă in achizitii publice.</strong></td>

                                </tr>

                                <tr>
                                    <td><strong>Monitorizare Reviste</strong></td>
                                    <td className="demo-center"><strong>Conține articole și analize din reviste, utile în procesul decizional.</strong></td>

                                </tr>

                                <tr>
                                    <td><strong>Doctrine</strong></td>
                                    <td className="demo-center"><strong>in toate ramurile de drept (Drept Administrativ, Drept Bancar, Drept Civil, Dreptul Comerțului Internațional, Drept Diplomatic și Consular, Dreptul Familiei, Dreptul Financiar, Dreptul Muncii, Drept Penal, Dreptul Transporturilor, Dreptul de Autor, ș.a.).</strong></td>

                                </tr>

                                <tr>
                                    <td><strong>Sinteze economice</strong></td>
                                    <td className="demo-center"><strong>Modulul de Sinteze economice, grupat pe tematici de interes general – Codul fiscal, Codul de procedură fiscală,
                                 Managementul resurselor umane este o compilație reușită realizată de către specialiștii noștri prin culegerea materialelor din diverse tratate și manuale de fiscalitate ale unor autori celebri, venind cu o multitudine de exemple practice și jurisprudență specifică.</strong></td>

                                </tr>

                                <tr>
                                    <td><strong>Modele tip</strong></td>
                                    <td className="demo-center"><strong>Modulul conține sute de modele tip de contracte, acțiuni în justiție, contestații, plângeri, cereri, reclamații, procuri, etc.</strong></td>
                                </tr>


                                <tr>
                                    <td><strong>Autorizații/Proceduri</strong></td>
                                    <td className="demo-center"><strong>Modulul oferă modele de autorizații, licențe și alte documente pe diverse domenii de interes.</strong></td>
                                </tr>



                                <tr>
                                    <td><strong>Dicționare</strong></td>
                                    <td className="demo-center"><strong>Dicționar român, latin, juridic, de drept civil, de drept al muncii, de drept internațional al afacerilor, bancar și de asigurari</strong></td>
                                </tr>



                                <tr>
                                    <td><strong>Definiții și abrevieri</strong></td>
                                    <td className="demo-center"><strong>Conține definițiile si abrevierile care se regasesc in legislatia romaneasca, cu posibilitatea cautarii unei expresii in definitii sau  in termeni si  hiperlink la actul in care a aparut abrevierea sau definitia cautata.</strong></td>
                                </tr>

                                <tr>
                                    <td><strong>Coduri si Clasificări</strong></td>
                                    <td className="demo-center"><strong>Modulul conține Clasificarea Ocupațiilor din Romania (COR), Clasificarea Activităților din Economia Naționala (CAEN), Clasificarea Produselor și Serviciilor Asociate Activităților din Economia Naționala (CPSA).</strong></td>
                                </tr>

                                <tr>
                                    <td><strong>Insolvență</strong></td>
                                    <td className="demo-center"><strong>Modulul permite monitorizarea entităților juridice in BPI ( Buletinul Procedurilor de Insolvență)</strong></td>
                                </tr>

                                <tr>
                                    <td><strong>Dosare in instanță</strong></td>
                                    <td className="demo-center"><strong>odulul permite căutarea și stocarea unei liste cu dosarele litigioase proprii ale unei instituții/autorități/persoane juridice/persoane fizice, evidențierea într-un Calendar- tip agendă, a termenelor de judecată din dosarele respective, precum și monitorizarea dosarelor.</strong></td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default DemoDetails;