

import RequestLegis from "./RequestLegis"
export default async function RequestLegisAccount(props) {

    if (props.creazaUser === true) //Cauta in Titlu
    {
        try {
            const result = await RequestLegis.PostNewAccount(props);
            return result;
        } catch (e) {
            console.log('error', e)
        }
    }
    else if(props.contact === true){
        try {
            const result = await RequestLegis.PostNewContact(props);
            return result;
        } catch (e) {
            console.log('error', e)
        }
    }
    else if(props.comanda === true){
        try {
            const result = await RequestLegis.PostNewComanda(props);
            return result;
        } catch (e) {
            console.log('error', e)
        }
    }
}


