import React from 'react';
import ArticolSumar from '../articol/ArticolSumar';
import '../articol/ArticolSumar.css';
import Modal from 'react-bootstrap/Modal';
import './Menu.css';
import { Link } from 'react-router-dom';
import HtmlPrezentare from '../../../components/body/legisPrezentare/LegisPrezentareHTML';
import RequestLegisAccount from "../../../requests/RequestLegisAccount";
import Footer from './../../footer/Footer';
import DespreNoi from '../legisPrezentare/DespreNoi.js'
import { Collapse } from 'react-bootstrap';
import { ClearHash } from './../../utils/ClearHash.js'
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
class Menu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showRegister: false,
            snackBarVisible: false
        };

        this.accordions = {
            aboutUs: <DespreNoi title={'Prezentare Legis'} body={'<p>abc</p>'} />
        };

        const hash = window.location.hash.length > 0 ? window.location.hash.split('#')[1] : '';

        this.state = {
            isAccordionOpen: this.props.isAccordionModuleOpen,
            accordionTitle: hash,
        };

        this.onInputchange = this.onInputchange.bind(this);
        this.registerUser = this.registerUser.bind(this);
        this.onCloseSnackBar = this.onCloseSnackBar.bind(this);

    }

    onCloseSnackBar() {
        this.setState({
            snackBarVisible: false
        })
    }

    toggleAccordion = (title) => {
        const { accordionTitle } = this.state;
        window.setTimeout(function () {
            if (title !== accordionTitle) {
                window.location.hash = title;
                this.props.onAccordionModuleChanged(true);
                this.setState({
                    isAccordionOpen: true,
                    accordionTitle: title
                });
            } else {

                ClearHash();
                this.setState({
                    accordionTitle: ''
                });
            }
        }.bind(this), 300);
    }


    onInputchange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    showRegister(val) {
        this.setState({
            showRegister: val
        });
    };

    async registerUser() {

        const errorMessages = [];
        const pwerr = this.getPasswordError();
        if (pwerr !== null) {
            errorMessages.push(pwerr);
        }

        if (this.state.password !== this.state.passwordConfirm) {
            errorMessages.push('Parola de confirmare nu coincide cu parola setata.');
        }

        this.setState({ errorMesages: errorMessages });

        if (errorMessages.length === 0) {

            //POST to WebApi
            await RequestLegisAccount({
                creazaUser: true,
                nume: this.state.nume,
                persoanaDeContact: this.state.persoanaDeContact,
                localitate: this.state.localitate,
                user: this.state.user,
                email: this.state.email,
                telefon: this.state.telefon,
                password: this.state.password
            });

            this.setState({
                snackBarVisible: true,
                showRegister: false
            })
        }
    }


    getPasswordError = () => {
        const password = this.state.password;

        if (password && password.length < 6) {
            return 'Parola trebuie sa fie de minim 6 caractere.';
        }

        let counter = 0;
        if (/\d/.test(password)) ++counter;
        if (/[a-z]/.test(password)) ++counter;
        if (/[A-Z]/.test(password)) ++counter;
        if (/[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password)) ++counter;

        if (counter < 2) {
            return 'Parola este prea simpla.';
        }

        return null;
    }


    handlePasswordConfirm = () => {
        const password = this.state.password;
        const confirm = this.state.passwordConfirm;

        if (password === confirm) {
            this.setState({
                passwordConfirmClass: 'modals-message-green',
                passwordConfirmMessage: 'Parola confirmata.'
            });
        } else {
            this.setState({
                passwordConfirmClass: 'modals-message',
                passwordConfirmMessage: 'Parola de confirmare nu coincide cu parola setata.'
            });
        }
    }

    handlePasswordChanged = (event) => {
        this.setState({
            passwordConfirmClass: 'modals-message',
            passwordErrorMessage: this.getPasswordError()
        });

        if (this.state.passwordConfirmMessage) {
            this.handlePasswordConfirm(event);
        }
    }

    openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    render() {

        var articoleFiltrateSumar = this.props.articoleFiltrateSumar;
        const { isAccordionOpen, accordionTitle } = this.state;
        return (
            <React.Fragment>
                <div className="menu">

                    <div className="controls row">

                        <div className="col-md-3 col-sm-3 col-xs-12" />


                        <div className="monitoring-control control col-xs-12 col-sm-4 col-lg-2 col-lg-offset-2">

                            <div className="control-inner" onClick={() => this.toggleAccordion('aboutUs')}>
                                <div className="control-content">
                                    <div className="control-graphic monitoring-graphic">
                                    </div>
                                    <div className="control-text">
                                        <h4>Module Legis</h4>
                                        <p>Despre noi</p>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="associate-portfolio-control control col-xs-12 col-sm-4 col-lg-2">
                            <Link to="creazaCont">
                                <div className="control-inner">
                                    <div className="control-content">
                                        <div className="control-graphic associate-control-graphic" />
                                        <div className="control-text">
                                            <h4>Creeaza cont si testeaza</h4>
                                            <p>Cont nou</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        {/*
                        <div className="company-portfolio-control control col-xs-12 col-sm-4 col-lg-2">
                            <Link to=""  onClick={() => this.openInNewTab('http://portal.datefirme.ro')}>
                                <div className="control-inner">
                                    <div className="control-content">
                                        <div className="control-graphic company-control-graphic" />
                                        <div className="control-text">
                                            <h4>Verifica Firme</h4>
                                            <p>Portal Date Firme</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>*/}

                        <div className="company-portfolio-control control col-xs-12 col-sm-4 col-lg-2">
                            <Link to="/comanda" >
                                <div className="control-inner">
                                    <div className="control-content">
                                        <div className="control-graphic company-control-graphic" />
                                        <div className="control-text">
                                            <h4>Comanda LEGIS</h4>
                                            <p>Vezi preturi si comanda</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        {/* <div className="company-portfolio-control control col-xs-12 col-sm-4 col-lg-2">
                            <Link to="/articoleNoi">
                                <div className="control-inner">
                                    <div className="control-content">
                                        <div className="control-graphic company-control-graphic" />
                                        <div className="control-text">
                                            <h4>Ultimele acte normative</h4>
                                            <p>Fii la curent cu noutatile</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div> */}


                    </div>
                    <div className="controls row">
                        <div className="col-md-12 col-sm-12 col-xs-12" >
                            <Collapse
                                in={this.props.isAccordionModuleOpen}
                                onEntering={el => el.scrollIntoView({ behavior: "smooth" })}
                            >
                                <div className="row">{this.accordions[accordionTitle]}</div>
                            </Collapse>
                        </div>
                    </div>

                    {this.props.articoleFiltrateSumar != null ?

                        <div className="row">
                            <ArticolSumar {...this.props} />
                        </div> : <div dangerouslySetInnerHTML={{ __html: HtmlPrezentare() }} />}


                </div>

                {/*
                <div className="col-xs-12 col-md-12 col-lg-12" >

                    <Snackbar open={this.state.snackBarVisible} autoHideDuration={6000} onClose={this.onCloseSnackBar}>
                        <Alert severity="success" className="alert" style={{ backgroundColor: '#f8d7da', height: '55px', textAlign: 'center', fontSize: '18px' }} >
                            Solicitare creare cont realizata cu succes!
                     </Alert>
                    </Snackbar>

                    <Modal style={{ width: '40%', marginTop: '5%', marginLeft: '30%', marginRight: '30%', overflow: 'auto' }}

                        dialogClassName="modal-dialog-user-nou"
                        show={this.state.showRegister}
                        onHide={() => this.showRegister(false)}
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                Inregistrare Cont
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.state.errorMesages != null ? this.state.errorMesages.map((message) => <p className="modals-message" >{message}</p>) : null}
                            <input type="text" className="modals-input form-control" name="nume" value={this.state.nume} onChange={this.onInputchange} placeholder="Nume / Nume societate sau institutie:" required />
                            <p />
                            <input type="text" className="modals-input form-control" name="persoanaDeContact" value={this.state.persoanaDeContact} onChange={this.onInputchange} placeholder="Persoana de contact:" required />
                            <p />
                            <input type="text" className="modals-input form-control" name="localitate" value={this.state.localitate} onChange={this.onInputchange} placeholder="Localitate:"
                                required
                            />

                            <p />
                            <input type="text" className="modals-input form-control" name="user" value={this.state.user} onChange={this.onInputchange} placeholder="User:" required />
                            <p />
                            <input type="email" className="modals-input form-control" name="email" value={this.state.email} onChange={this.onInputchange} placeholder="Email:" required />
                            <p />
                            <input type="number" className="modals-input form-control" name="telefon" value={this.state.telefon} onChange={this.onInputchange} placeholder="Telefon" />
                            <p />
                            <input type="password" className="modals-input form-control" name="password" value={this.state.password} onChange={this.onInputchange} placeholder="Parola"
                                onBlur={this.hidePasswordRequirements}
                                onKeyUp={this.handlePasswordChanged}
                                required

                            />
                            <p />
                            <input type="password" className="modals-input form-control" name="passwordConfirm" value={this.state.passwordConfirm} onChange={this.onInputchange} placeholder="Confirma Parola"
                                onKeyUp={this.handlePasswordConfirm}
                                required
                            />
                            <p />

                            <div >
                                <input className="modals-check" type="checkbox" required />&nbsp;
                                    Sunt de acord ca adresa de email si numarul de telefon să fie folosite pentru a primi serviciile si informările Legis.
                                </div>
                            <button type="submit" className="btn btn-secondary" onClick={() => this.registerUser()}>Trimite Cererea</button>
                            <div className="clearfix" />
                        </Modal.Body>
                    </Modal>
                </div> */}

                <div className="top-buffer"></div>
                <Footer />
            </React.Fragment>
        );
    }
}

export default Menu;
