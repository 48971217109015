

import RequestLegis from "./RequestLegis"
export default async function RequestLegisAdmin(props) {

    if (props.expresiiCustom != null && props.expresiiCustom === true) //Returneaza Lista Expresii Custom
    {
        try {
            const result = await RequestLegis.getExpresiiCustom(props.pagina, props.articolePePagina);
            return result;
        } catch (e) {
            console.log('error', e)
        }
    }
    else if (props.articoleExpresieCustom != null && props.articoleExpresieCustom === true) {
        try {
            const result = await RequestLegis.getArticoleExpresieCustom(props.idArticolCustom);
            return result;
        } catch (e) {
            console.log('error', e)
        }
    }
    else if (props.articoleExpresieCustomChanges != null && props.articoleExpresieCustomChanges === true) {
        try {
            const result = await RequestLegis.PostArticoleExpresie(props);
            return result;
        } catch (e) {
            console.log('error', e)
        }
    }else if (props.articolById != null && props.articolById === true) {
        try {
            const result = await RequestLegis.getArticolById(props.articolId);
            return result;
        } catch (e) {
            console.log('error', e)
        }
    }else if (props.addExpresieCustom != null && props.addExpresieCustom === true) {
        try {
            const result = await RequestLegis.PostExpresieCustom(props);
            return result;
        } catch (e) {
            console.log('error', e)
        }
    }
}

