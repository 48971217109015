import React, { useState, useEffect } from 'react';
import './ContactPage.css';
import RequestLegisAccount from '../../../requests/RequestLegisAccount';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

class ContactPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            errorMessages: [],
            contact: true,
            email: '',
            subiect: '',
            telefon: '',
            mesaj: ''
        };

        this.onInputchange = this.onInputchange.bind(this);
        this.onCloseSnackBar = this.onCloseSnackBar.bind(this);
    }


    onInputchange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    onCloseSnackBar() {
        this.setState({
            snackBarVisible: false
        })
    }


    async trimiteMesaj() {
        const errorMessages = [];
        if (this.state.subiect == null)
            errorMessages.push('Subiect obligatoriu')

        if (this.state.mesaj == null)
            errorMessages.push('Mesaj obligatoriu')

        this.setState({ errorMesages: errorMessages });

        if (this.state.email == null)
            errorMessages.push('Email obligatoriu')

        if (this.state.email != null && !this.state.email.includes('@'))
            errorMessages.push('Email invalid')


        if (errorMessages.length === 0) {
            //POST to WebApi
            await RequestLegisAccount({

                contact: true,
                email: this.state.email,
                subiect: this.state.subiect,
                telefon: this.state.telefon,
                mesaj: this.state.mesaj
            });
            this.setState({
                snackBarVisible: true,
                showContact: false
            })
        }
    }

    render() {

        return (
            <div className="contact">
                <div className="contactInfo">
                    <span>
                        <b style={{ color: '#42A0CE' }}  >Contactați-ne</b>

                    </span>
                    <p>
                        Fie că doriți să ne contactați telefonic sau prin intermediul e-mailului,<br /> suntem disponibili în orice moment să vă răspundem solicitărilor!
                    </p>

                    <div>
                        <div className="sediuPrincipal" />
                        <b>Sediul Principal</b>
                        <div className="row">
                            <span className="category">610076 Bdul Decebal 32</span>
                        </div>
                        <div className="row">
                            <span className="category">Piatra Neamt, NEAMT </span>
                        </div>
                    </div>

                    <br />
                    <div>
                        <div className="info" />
                        <b>Filiala Transilvania</b>
                        <div className="row">
                            <span className="category"> 400029 Str. Cardinal Iuliu Hossu 16</span>
                        </div>

                        <div className="row">
                            <span className="category"> Cluj-Napoca, CLUJ</span>
                        </div>
                    </div>

                    <br />
                    
                    <div>

                    &nbsp;<b>Contactați-ne:</b>
                    <br/>
                    <br/>
                        <div className="contactA" />
                        &nbsp;<b>Ofertare - Informatii produs:</b>
                        <span className="category"> 0723-322.517 | 0264-596.450 </span>
                            <br/>
                            &nbsp;<span className="category"> vanzari@legis.ro </span>


                        <br/>
                        <br/>
                        <div className="contactB" />  
                        &nbsp;<b>Suport tehnic clienti:</b>
                       <span className="category"> 0773-329.587 | 0264-411.360</span> 
                       <br/>
                            &nbsp;<span className="category"> legis@legis.ro </span>

                       <br/>
                        <br/>
                        <div className="contactC" />  
                        &nbsp;<b>Relatii clienti - Administrativ:</b>
                       <span className="category"> 0264-412.797 | 0264-412.808 | 0720.533.289</span>
                       <br/>
                            &nbsp;<span className="category"> administrativ@legis.ro </span>
                        
                       <br/>
                        <br/>
                       <div className="contactD" />  
                        &nbsp;<b>Relatii clienti - Facturare:</b>
                        <span className="category"> 0264-411.350 | 0728-992.371</span>
                         <br/>
                            &nbsp;<span className="category"> rita@legis.ro </span>
                        
                    </div>

                   
                </div>


                <div className="contactMessage">
                    <Snackbar open={this.state.snackBarVisible} autoHideDuration={6000} onClose={this.onCloseSnackBar}>
                        <Alert severity="success" className="alert"  style={{height:'55px',textAlign:'center',fontSize:'18px'}} >
                            Mesaj trimis cu succes!
                     </Alert>
                    </Snackbar>
                    {this.state.errorMesages != null ? this.state.errorMesages.map((message) => <p className="modals-message" >{message}</p>) : null}
                    <span>
                        <b style={{ color: '#42A0CE' }}>Trimite Email</b>
                    </span>
                    <input type="email" className="modals-input form-control" name="email" value={this.state.email} onChange={this.onInputchange} placeholder="Adresa Email:" required />
                    <p />
                    <input type="number" className="modals-input form-control" name="telefon" value={this.state.telefon} onChange={this.onInputchange} placeholder="Telefon:" required />
                    <p />
                    <input type="text" className="modals-input form-control" name="subiect" value={this.state.subiect} onChange={this.onInputchange} placeholder="Subiect:"
                        required
                    />

                    <p />
                    <textarea type="textarea" className="modals-input form-control textareaField" name="mesaj" value={this.state.mesaj} onChange={this.onInputchange} placeholder="Mesaj:" required />
                    <p />


                    <button type="submit" className="btn btn-secondary" onClick={() => this.trimiteMesaj()}>Trimite</button>
                    <div className="clearfix" />
                </div>
            </div>
        );
    }
}

export default ContactPage;