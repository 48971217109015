import React from 'react';
import '../contact/Contact.css';
import Modal from 'react-bootstrap/Modal';
import RequestLegisAccount from '../../../requests/RequestLegisAccount';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
class Contact extends React.Component {


    constructor() {
        super();
        this.state = {
            showContact: true,
            snackBarVisible: false
        }

        this.onInputchange = this.onInputchange.bind(this);
        this.onCloseSnackBar = this.onCloseSnackBar.bind(this);
    }

    onCloseSnackBar() {
        this.setState({
            snackBarVisible: false
        })
    }
    async trimiteMesaj() {
        const errorMessages = [];
        if (this.state.subiect == null)
            errorMessages.push('Subiect obligatoriu')

        if (this.state.mesaj == null)
            errorMessages.push('Mesaj obligatoriu')

        this.setState({ errorMesages: errorMessages });


        if (errorMessages.length === 0) {
            //POST to WebApi
            await RequestLegisAccount({

                contact: true,
                email: this.state.email,
                subiect: this.state.subiect,
                telefon: this.state.telefon,
                mesaj: this.state.mesaj
            }); 
            this.setState({
                snackBarVisible: true,
                showContact : false
            })
        }
    }
    showContact(val) {
        this.props.showContact(val);
    };

    onInputchange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    render() {
        return (
            <div>

                <Snackbar open={this.state.snackBarVisible} autoHideDuration={6000} onClose={this.onCloseSnackBar}>
                    <Alert severity="success" className="alert"  style={{ height:'55px',textAlign:'center',fontSize:'18px'}} >
                        <b>Mesaj trimis cu succes!</b>
                     </Alert>
                </Snackbar>
                <Modal

                    dialogClassName="modalContact"
                    show={this.state.showContact}
                    onHide={() => this.showContact(false)}
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Contact
                            </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.errorMesages != null ? this.state.errorMesages.map((message) => <p className="modals-message" >{message}</p>) : null}
                        <input type="email" className="modals-input form-control" name="email" value={this.state.email} onChange={this.onInputchange} placeholder="Adresa Email:" required />
                        <p />
                        <input type="number" className="modals-input form-control" name="telefon" value={this.state.telefon} onChange={this.onInputchange} placeholder="Telefon:" required />
                        <p />
                        <input type="text" className="modals-input form-control" name="subiect" value={this.state.subiect} onChange={this.onInputchange} placeholder="Subiect:"
                            required
                        />

                        <p />
                        <textarea type="textarea" className="modals-input form-control textareaField" name="mesaj" value={this.state.mesaj} onChange={this.onInputchange} placeholder="Mesaj:" required />
                        <p />


                        <button type="submit" className="btn btn-secondary" onClick={() => this.trimiteMesaj()}>Trimite</button>
                        <div className="clearfix" />
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}


export default Contact;