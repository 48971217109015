import React from 'react';
import AppModule from './AppModule';
import './App.css';
class App extends React.Component {
    
    render() {
        return (
            <div>
            <AppModule/>
            </div>
        );
    }
}

export default App;

