import React from 'react';
import Modal from 'react-bootstrap/Modal';
import './DevinPartener.css'
class DevinPartener extends React.Component {


    constructor() {
        super();
        this.state = {
            showDevinPartener: true,
        }

        this.showDevinPartener = this.showDevinPartener.bind(this);
    }

    
    showDevinPartener(val) {
        this.props.showDevinPartener(val);
    };


    render() {
        return (
            <div>
                <Modal 

                    dialogClassName="modalContact"
                    show={this.state.showDevinPartener}
                    onHide={() => this.showDevinPartener(false)}
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Devino Partener
                            </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Căutăm parteneri de distribuție alături de care să ne indreptăm către un obiectiv comun: dezvoltarea permanentă a afacerii.</p>
                        
                        <h3>Care sunt avantajele?</h3>
                        <ul>
                            <li>Nu trebuie să-ti dedici timpul integral! Lucreaza când poți!</li>
                            <li>Promovezi un produs de încredere si recunoscut pe piață</li>
                            <li>Un client adus de tine, iti aduce un <strong>comision recurent lunar</strong>
                                    (atât timp cât clientul este activ, fără restrictii)</li>
                            <li>Fă doar o recomandare și ne ocupăm noi de incheierea contractului, clientul este al tău.</li>
                        </ul>
                        Dacă dorești să devii partener iLEGIS contactează-ne să facem cunoștință și să creăm împreună o echipă.
                        <div className="clearfix" />
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}


export default DevinPartener;