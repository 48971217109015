import React from "react";
import RequestLegisArticol from "../../../requests/RequestLegisArticol";
import './ArticolDetails.css';
class ArticolDetailsPage extends React.Component {
    constructor() {
        super();
        this.state = {
            artContent: '',
            Id: 0
        };
    }

    componentDidMount() {

        var Id = 0;
        if (this.props != null && this.props.location != null && this.props.location.pathname != null) {
            var pathName = this.props.location.pathname;
            var lastItem = pathName.split("-").pop().replace("=", "");
            if (lastItem != null) {
                Id = Number(lastItem);
            }
        }
        if (Id > 0 ) {
            this.setState({ Id: Number(Id) });
        }
    }
    componentDidUpdate() {
        if (this.state.Id > 0 && this.state.artContent == '') {
            this.getArticolContent();
        }
    }
    async getArticolContent() {

        let articolContentResponse = await RequestLegisArticol(this.state);
        if (articolContentResponse != null) {
            this.setState({
                artContent: articolContentResponse,
            });
        }
    }

    render() {
        let content = this.state.artContent;

        return (
            <div style={{ margin: '14px' }}>
                <div
                    dangerouslySetInnerHTML=
                    {{
                        __html: content.Continut
                    }}
                />
            </div>
        );
    }
}
export default ArticolDetailsPage;