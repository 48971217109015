import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import React from "react";
import Slide from "@material-ui/core/Slide";
import {makeStyles} from "@material-ui/core/styles";

const useStylesPagePanels = makeStyles(theme => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        maxWidth: 'none',
    },
    gridItem: {
        transition: theme.transitions.create(['max-width', 'flex-basis'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shortest,
        }),
    },
}));


export default function PagePanels({children}) {
    const classes = useStylesPagePanels();

    const validChildren = React.Children.toArray(children).filter((child) => React.isValidElement(child));
    let widths;
    if (validChildren.length === 1) {
        widths = [12];
    } else if (validChildren.length === 2) {
        widths = [5, 7];
    }

    let gridItems = validChildren.map((panel, i) => {
        if (i === 0) {
            return (
                <Grid item xs={widths[i]} className={classes.gridItem}>
                    {panel}
                </Grid>
            )
        } else {
            return (
                <Slide direction="left" in={true} style={{transitionDelay: '200ms'}}>
                    <Grid item xs={widths[i]} className={classes.gridItem}>
                        {panel}
                    </Grid>
                </Slide>
            )
        }
    });

    console.log(gridItems);

    return (
        <Container className={classes.container}>
            <Grid container spacing={2} style={{height: '100%'}}>
                {gridItems}
            </Grid>
        </Container>
    )
};