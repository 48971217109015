import React, { Fragment } from "react";
import AppBar from "@material-ui/core/AppBar";
import Paper from "@material-ui/core/Paper";
import '../administrare/AdministrareArticole.css';
import PagePanels from "../administrare/PagePanels";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AdministrareVizualizareArtOrdCustom from "../administrare/AdministrareVizualizareArtOrdCustom";
import AdministrareAdaugaArtOrdCustom from "../administrare/AdministrareAdaugaArtOrdCustom";
class AdministrareArticole extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            tabs: [
                <AdministrareVizualizareArtOrdCustom {...props} />,
                <AdministrareAdaugaArtOrdCustom {...props} />,
            ]
        };
        this.handleChange = this.handleChange.bind(this);
    }


    handleChange(event, newValue) {
        this.setState({ value: newValue });
    }

    a11yProps(index) {
        return {
            id: `tab-${index}`,
            'aria-controls': `tabpanel-${index}`,
        };
    }

    render() {
        return (

            <PagePanels>
                <Fragment>
                    <AppBar position="static" color="primary">
                        <Tabs value={this.state.value} onChange={this.handleChange} centered>
                            <Tab label="Lista Expresii Custom"  {...this.a11yProps(0)} />
                            <Tab label="Adauga Expresii Custom"  {...this.a11yProps(1)} />
                        </Tabs>
                    </AppBar>
                    <Paper square="true">

                        {this.state.tabs[this.state.value]}
                    </Paper>
                </Fragment>
            </PagePanels>
        );
    }
}

export default AdministrareArticole;