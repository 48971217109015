import React from "react";
import { Button } from '@material-ui/core';
import RequestLegisAdmin from "../../../requests/RequestLegisAdmin";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import '../administrare/AdministrareAdaugaArtOrdCustom.css';
class AdministrareAdaugaArtOrdCustom extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            snackBarVisible: false,
            expresieCustomNoua:'',
        };

        this.onCloseSnackBar = this.onCloseSnackBar.bind(this);
        this.onAdaugaExpresieCustom = this.onAdaugaExpresieCustom.bind(this);
        this.handleExpresieNouaChange = this.handleExpresieNouaChange.bind(this);
    }

    onCloseSnackBar() {
        this.setState({
            snackBarVisible: false
        })
    }

    handleExpresieNouaChange(event) {
        this.setState({ expresieCustomNoua: event.target.value });
    }

    async onAdaugaExpresieCustom() {
        var response = await RequestLegisAdmin({
            expresieCustom: this.state.expresieCustomNoua,
            addExpresieCustom: true
        });

        if(response !=null){
            this.setState({
                snackBarVisible : true
            })
        }
    }

    render() {
        return (
            <div>
            <h5>Adauga Expresie Custom</h5>
                <div>
                    <input
                        type='text'
                        className='form-control'
                        placeholder='Expresie Custom...'
                        onChange={this.handleExpresieNouaChange}
                    />
                    <Button style={{ marginTop: '5px', marginBottom: '5px' }} variant="contained" color="primary" size="medium" onClick={() => this.onAdaugaExpresieCustom()} > Adauga</Button>
                </div>
                <Snackbar open={this.state.snackBarVisible} autoHideDuration={6000} onClose={this.onCloseSnackBar}>
                    <Alert severity="success" className="alert" style={{ backgroundColor: '#f8d7da', height: '55px', textAlign: 'center', fontSize: '18px' }} >
                        Expresie adaugata cu success!
                     </Alert>
                </Snackbar>
            </div>
        );
    }
}

export default AdministrareAdaugaArtOrdCustom;