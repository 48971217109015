import React from 'react';
import RequestLegisArticol from "../../../requests/RequestLegisArticol";
import { Link } from 'react-router-dom';
import '../articolNou/ArticolNou.css';
import { Button } from '@material-ui/core';

class ArticolNou extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pagina: 1,
            perPage: 10,
            totalPagini: 1,
            getArticoleNoi: true,
            isLoading: true,
            isLoadingMore: false,
            articoleNoi: []
        };

    }

    async componentDidMount() {

        try {
            var response = await RequestLegisArticol({
                pagina: 1,
                getArticoleNoi: this.state.getArticoleNoi,
                articolePePagina: this.state.perPage
            });

            if (response != null)
                this.setState({
                    articoleNoi: response,
                    pagina: this.state.pagina + 1,
                    isLoading: false,
                })
        } catch (e) {
            console.log('error=', e)
        }
    }

    async incarcaMaiMulteClick() {
        try {
            this.setState({
                isLoadingMore: true
            })
            var response = await RequestLegisArticol({
                pagina: this.state.pagina,
                getArticoleNoi: this.state.getArticoleNoi,
                articolePePagina: this.state.perPage
            });

            if (response != null)
                this.setState({
                    articoleNoi: response,
                    isLoadingMore: false,
                    pagina: this.state.pagina + 1,
                    articoleNoi: this.state.articoleNoi.concat(response)
                })
        } catch (e) {
            console.log('error=', e)
        }
    }

    render() {

        const { articoleNoi } = this.state;
        const artNoiLst = [];
        if (articoleNoi != null && articoleNoi.length > 0) {

            for (let i = 0; i < articoleNoi.length; i++) {
                const articol = articoleNoi[i];
                artNoiLst.push(
                    <div key={i} className="recently-viewed ">
                        <div className="recently-viewed-card col-xs-12 col-lg-12 col-lg-offset-4">

                            <div className="company-card ">
                                <div className="card-graphic">

                                    <Link className="company-graphic" />
                                </div>
                                <div className="card-content">

                                    <div className="card-content-wrapper">
                                        {console.log('titlu=',articol.Titlu.replace("/","\\"))}
                                        <div className="card-row row">
                                            <div className="pull-left card-space">
                                                <span className="category">Tip Act: </span>
                                                <span className="value"><Link target={"_blank"} to={`/articolDetails/${articol.Titlu.replace(/\//g,' ').replace(/\s+/g, '-')}-${articol.Id}`}  >{articol.TipArticol ? articol.TipArticol : 'Vezi Act'}</Link></span>
                                            </div>
                                        </div>
                                        <div className="card-row row">
                                            <div className="pull-left card-space">
                                                <span className="category">Data Emiterii: </span>
                                                <span className="value">{articol.TitluData ? articol.TitluData : '-'}</span>
                                            </div>
                                        </div>
                                        
                                        <div className="card-row row">
                                            <div className="pull-left card-space">
                                                <span className="category" style={{textAlign:'left'}}>Titlu: </span>
                                                <span>{articol.Titlu ? articol.Titlu.trim() : '-'}</span>
                                            </div>
                                        </div>

                                        <div className="card-row row">
                                            <div className="pull-left card-space">
                                                <span className="category">Emitent: </span>
                                                <span className="value">{articol.Emitent ? articol.Emitent : '-'}</span>
                                            </div>
                                        </div>

                                        <div className="card-row row">
                                            <div className="pull-left card-space">
                                                <span className="category">Publicat In: </span>
                                                <span className="value">{articol.PublicatIn ? articol.PublicatIn : '-'} </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="spacer" />
                        </div>
                    </div>
                );
            }
        }
        return (
            <div className="recently-added ">
                {this.state.isLoading == true && this.state.isLoadingMore != true ? <div className="loading2">
                    <p className="loading-text">Vă rugăm, asteptaţi. Datele se încarcă</p>
                </div>
                    : <div className="recently-added-head ">


                        <h2>Adaugate Recent</h2>

                        {artNoiLst}

                        <div className="container">
                            <div className="right">
                                {this.state.isLoadingMore == true ? <div className="loading-more">

                                </div>

                                    : null}
                            </div>
                            <div className="left">
                                <Button variant="outlined" color="secondary" onClick={() => this.incarcaMaiMulteClick(this)}>
                                    Incarca mai multe...

                            </Button>
                            </div>
                        </div>
                    </div>}
            </div>
        );
    }
}

export default ArticolNou;